import apiCall from "./apiCall";
// eslint-disable-next-line import/no-anonymous-default-export
export default {


    async listAllDocs() {

        const response = await apiCall("pmis-docs/list/all", []);
        return response;

    },

    async getTitleByID(title_id) {
        let data = {
            "title_id": title_id
        }

        const response = await apiCall("pmis-docs/get/single-title", data);
        return response;

    },

    async getSubtitleInfo(subtitle_id) {
        let data = {
            "subtitle_id": subtitle_id
        }

        const response = await apiCall("pmis-docs/get/single-subtitle-info", data);
        return response;

    },




    async createMainDoc(title, description) {
        let data = {
            "title": title,
            "description": description
        }

        const response = await apiCall("pmis-docs/create/main-title", data);
        return response;

    },

    async createMainDocDescription(title_id, maindoc_description) {
        let data = {
            "title_id": title_id,
            "maindoc_description": maindoc_description
        }

        const response = await apiCall("pmis-docs/create/main-doc/description", data);
        return response;
    },

    async createSubDoc(title_id, subtitle, subtitle_description) {

        let data = {
            "title_id": title_id,
            "subtitle": subtitle,
            "subtitle_description": subtitle_description
        }
        const response = await apiCall("pmis-docs/create/subtitle", data);
        return response;


    },


    async createSubtitleDescription(subtitle_id, subtitle_description) {
        let data = {
            "subtitle_id": subtitle_id,
            "subtitle_description": subtitle_description
        }

        const response = await apiCall("pmis-docs/add/subtitle-description", data);
        return response;

    },


    async updateMainDocTitle(doc_id, title) {
        let data = {
            "title_id": doc_id,
            "title": title,
            // "description": description
        }

        const response = await apiCall("pmis-docs/update/main-title", data);
        return response;

    },

    async updateSubDocTitle(subtitle_id, subtitle) {
        let data = {
            "subtitle_id": subtitle_id,
            "subtitle": subtitle,
        }

        const response = await apiCall("pmis-docs/update/subtitle", data);
        return response;

    },


    async deleteSubTitle(subtitle_id, title_id) {
        let data = {
            "subtitle_id": subtitle_id,
            "title_id": title_id
        }

        const response = await apiCall("pmis-docs/delete/subtitle", data);
        return response;

    },


    async deleteMainTitle(title_id) {
        let data = {
            "title_id": title_id
        }

        const response = await apiCall("pmis-docs/delete/maintitle", data);
        return response;

    },


    async updateMainDescription(title_id, description) {
        let data = {
            "title_id": title_id,
            "description": description,
        }

        const response = await apiCall("pmis-docs/update/maintitle_description", data);
        return response;

    },

    async updateSubtitleDescription(subtitle_id, description) {
        let data = {
            "subtitle_id": subtitle_id,
            "subtitle_description": description,
        }
        const response = await apiCall("pmis-docs/update/subtitle_description", data);
        return response;

    },


    async createPageSummary(doc_id, heading, doc_ref) {

        let data = {
            "doc_id": doc_id,
            "heading": heading,
            "doc_ref": doc_ref
        }

        console.log(data)
        const response = await apiCall("pmis-docs/create/page-summary-heading", data);
        return response;


    }




}
