import React from 'react'
import { Link } from 'react-router-dom'
// import { AuthConsumer } from '../../Context/AuthContext'
import functions from '../../utils/functions'
import NoContent from '../../Components/Common/NoContent';
import PopAddPageSummary from '../../Components/PopUps/PopAddPageSummary';
import ajaxPmisDocs from '../../utils/remote/ajaxPmisDocs';
import Toast from '../../Components/Common/Toast';
import { RenderAddIcon } from '../../Components/Common/Icons/AddIcons';
import Tippy from '@tippyjs/react';
import DocumentationContext from '../../Context/DocumentationContext';
import AnchorLink from 'react-anchor-link-smooth-scroll';
// import AnchorLink from 'react-anchor-link-smooth-scroll'

class ProjectMenu extends React.Component {

    state = {
        activeLocationPath: window.location.pathname,
        modal: false,
        info: "",
        anchorID: ""
    }

    static contextType = DocumentationContext;

    onClickAnchorLink = (anchorID) => {
        this.setState({
            anchorID: ""
        }, () => this.setState({
            anchorID: anchorID
        }))
    }

    onSaveHeading = async (heading) => {

        const server_response = await ajaxPmisDocs.createPageSummary(this.props.doc_id, heading, this.props.doc_ref)
        functions.closePopup("modal_add_page_summary");
        if (server_response.status === "OK") {
            const { listAllTitles } = this.context;
            listAllTitles()
            this.setState({
                info: <Toast message={server_response.details.message} />
            })
        } else {
            this.setState({
                info: <Toast message={server_response.details.message} type={2} />
            })
        }
    }



    onClickAddPageSummary = () => {

        this.setState({
            modal: false,
        }, () => {
            this.setState({
                modal: <PopAddPageSummary
                    onClickSave={this.props.onSaveHeading}
                />
            }, () => functions.openPopup("modal_add_page_summary")
            )
        })

    }



    render() {

        const path = this.state.activeLocationPath
        const roleList = functions.roleGuard()
        const pageHeadingList = this.props.pageHeadingsList

        const { anchorID } = this.state;

        return (
            <>

                {/* // <AuthConsumer>
            //     {props => {
            //         const permissionList = props.permissionList;
            //         return ( */}
                {this.state.modal}
                {this.state.info}

                <div className="col-xl-3 col-lg-12 d-none d-xl-block custom-leftnav">
                    <div className="main-content-left-components">
                        <div className="card custom-card">
                            <div className="card-body config-component-item">



                                {((pageHeadingList || this.props.sider)) ?
                                    <>
                                        <div className={`d-flex align-items-center justify-content-between`}>
                                            <h5>On this page</h5>

                                            <span className="sidemenu-label float-right">
                                                <Tippy content="Click to create heading">
                                                    <span
                                                        onClick={() => this.onClickAddPageSummary()}
                                                        // onClick={this.onClickAddTitle}
                                                        className='rounded add-icon-cursor d-flex align-items-center justify-content-center w-5'
                                                    >
                                                        <i className="fs-16 text-primary fas fa-plus"></i>
                                                    </span>
                                                </Tippy>
                                            </span>
                                        </div>

                                        <nav className="mt-3 nav flex-column">
                                            {/* {functions.findInPermission(permissionList, 'OPERATIONS-00') && */}
                                            {pageHeadingList && !this.props.installation && Array.isArray(pageHeadingList) &&
                                                pageHeadingList.map((item, key) => (<>
                                                    {/* <span
                                                    // className={`nav-link ${path === '/config/branches' && 'active'}`}
                                                    >Prerequisite</span>
                                                    <span
                                                    // className={`nav-link ${path === '/config/streets' && 'active'}`}
                                                    >Scope</span> */}
                                                    <span className={`d-flex align-items-center`}>{item.heading} &nbsp;
                                                        {/* {<RenderAddIcon
                                                            onClickAdd={this.onClickAdd}
                                                            tipText={"Click to add subtitle"}
                                                            extraClasses="add-title-icon-cursor"
                                                        />} */}

                                                        {/* <ul className='ml-4'>
                                                            <li>Install Ubuntu</li>
                                                            <li>Install Apache</li>
                                                            <li>Install MySQL</li>
                                                            <li>Setting up the database</li>
                                                            <li>Install php</li>
                                                            <li>Setting up PMIS directories</li>
                                                            <li>Migrating the code</li>
                                                            <li>Configuring the backend</li>
                                                            <li>Configuring the apache virtual hosts</li>
                                                            <li>Installing NodeJS</li>
                                                            <li>Performance optimization</li>
                                                        </ul> */}
                                                    </span>
                                                </>))}
                                        </nav>

                                        {this.props.installation && <nav className="mt-3 nav flex-column">
                                            {/* {functions.findInPermission(permissionList, 'OPERATIONS-00') && */}
                                            <>
                                                <a href="#prerequisite" className={`nav-link ${anchorID === 'prerequisite' && 'active'}`} onClick={() => this.onClickAnchorLink("prerequisite")}
                                                >Prerequisite</a>

                                                {/* <AnchorLink href='#prerequisite'>Prerequisite</AnchorLink> */}
                                                {/* <AnchorLink href='#scope'>Scope</AnchorLink> */}
                                                <a href="#scope" className={`nav-link ${anchorID === 'scope' && 'active'}`} onClick={() => this.onClickAnchorLink("scope")}>Scope</a>
                                                <a href="#environment-setup" className={`nav-link ${anchorID === 'environment-setup' && 'active'}`} onClick={() => this.onClickAnchorLink("environment-setup")}>Environment Setup</a>
                                                <ol className='ml-3 d-flex flex-column'>
                                                    <a href="#install-ubuntu" className={`nav-link p-0 m-0 ${anchorID === 'install-ubuntu' && 'active'}`} onClick={() => this.onClickAnchorLink("install-ubuntu")}>Install Ubuntu</a>
                                                    <a href="#install-apache" className={`nav-link p-0 m-0 ${anchorID === 'install-apache' && 'active'}`} onClick={() => this.onClickAnchorLink("install-apache")}>Install Apache</a>
                                                    <a href="#install-mysql" className={`nav-link p-0 m-0 ${anchorID === 'install-mysql' && 'active'}`} onClick={() => this.onClickAnchorLink("install-mysql")}>Install MySQL</a>
                                                    <a href="#setting-up-database" className={`nav-link p-0 m-0 ${anchorID === 'setting-up-database' && 'active'}`} onClick={() => this.onClickAnchorLink("setting-up-database")}>Setting up the database</a>
                                                    <a href="#install-php" className={`nav-link p-0 m-0 ${anchorID === 'install-php' && 'active'}`} onClick={() => this.onClickAnchorLink("install-php")}>Install php</a>
                                                    <a href="#php-directories" className={`nav-link p-0 m-0 ${anchorID === 'php-directories' && 'active'}`} onClick={() => this.onClickAnchorLink("php-directories")}>Setting up PMIS directories</a>
                                                    <a href="#migrating-code" className={`nav-link p-0 m-0 ${anchorID === 'migrating-code' && 'active'}`} onClick={() => this.onClickAnchorLink("migrating-code")}>Migrating the code</a>
                                                    <a href="#configuring-backend" className={`nav-link p-0 m-0 ${anchorID === 'configuring-backend' && 'active'}`} onClick={() => this.onClickAnchorLink("configuring-backend")}>Configuring the backend</a>
                                                    <a href="#apache-hosts" className={`nav-link p-0 m-0 ${anchorID === 'apache-hosts' && 'active'}`} onClick={() => this.onClickAnchorLink("apache-hosts")}>Configuring the apache virtual hosts</a>
                                                    <a href="#installing-nodejs" className={`nav-link p-0 m-0 ${anchorID === 'installing-nodejs' && 'active'}`} onClick={() => this.onClickAnchorLink("installing-nodejs")}>Installing NodeJS</a>
                                                    <a href="#performance-optimazation" className={`nav-link p-0 m-0 ${anchorID === 'performance-optimization' && 'active'}`} onClick={() => this.onClickAnchorLink("performance-optimization")}>Performance optimization</a>
                                                </ol>

                                            </>
                                        </nav>}
                                    </>
                                    :
                                    <NoContent
                                        width='50%'
                                        subTitle="You havent added any headings for your documents. Click the add button below to create subtopics for this page"
                                        onClick={() => this.onClickAddPageSummary()}
                                    />
                                }
                            </div>
                        </div>


                    </div>
                </div>

                {/* //         )
            //     }}
            // </AuthConsumer> */}
            </>

        )
    }

}

export default ProjectMenu
