import React from 'react';

import { DocumentationProvider } from './DocumentationContext';
import { AuthConsumer, AuthProvider } from './AuthContext';
import { ThemeProvider } from './ThemeContext';


const SuperProvider = (props) => {

    return (
        <AuthProvider>
            <AuthConsumer>
                {authProps => {
                    if (authProps.permissionList) {
                        return (
                            <ThemeProvider auth={authProps}>
                                <DocumentationProvider>
                                    {props.children}
                                </DocumentationProvider>
                            </ThemeProvider>
                        )
                    } else {
                        return props.children
                    }

                }}
            </AuthConsumer>
        </AuthProvider>
    )
}

export default SuperProvider;