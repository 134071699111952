import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';


class WarnSuccess extends React.Component {
  state = {
    buttonLabel: this.props.buttonLabel || "Confirm Delete",
    loading: false
  }


  closeDialogue = () => {
    $("#pop_success").modal('hide');
  }

  render() {

    return (
      <div className="modal" id="pop_success">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content tx-size-sm">
            <div className="modal-body tx-center pd-y-20 pd-x-20">
              <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button> <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-success lh-1 mg-t-20 d-inline-block"></i>
              <h4 className="tx-success tx-semibold mg-b-20">{this.props.title}</h4>
              <p className="mg-b-20 mg-x-20">{this.props.description}</p>
              {this.props.route && <Link
                to={this.props.route}
                className="btn ripple btn-success pd-x-25"
                type="button"
                onClick={this.closeDialogue}>{this.props.linkTitle || "Continue"}</Link>}
              {this.props.customLink}
            </div>
          </div>
        </div>
      </div>
    )


  }
}

export default WarnSuccess;
