import React from 'react'
import Cookies from 'universal-cookie';
import AlertError from '../../Components/Common/AlertError';
import dictionary from '../../utils/dictionary';
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import ActivityLoader from '../../Components/Common/ActivityLoader';

class Login extends React.Component {

    state = {
        username: '',
        password: '',
        error: false,
        info: '',
        loading: false,
        // login: false,
        info: ''
    }


    componentDidMount() {
        // this.timerLogin = setTimeout(this.funcLogin = async () => {
        //   await this.onPressLogin();
        //   this.timerLogin = setTimeout(this.funcLogin, dictionary._waitTime); // (*)
        // }, dictionary._waitTime);

    }

    componentWillUnmount() {
        // clearTimeout(this.timerLogin)
    }

    //show password when when u click checkbox
    showPassword = () => {
        let input = document.getElementById("password");
        if (input.type === "password") {
            input.type = "text";
        } else {
            input.type = "password";
        }
    }

    onPressLogin = async (event) => {

        this.setState({
            info: <ActivityLoader />
        })

        event.preventDefault();
        const { username, password } = this.state;

        if (
            username.length > 0 &&
            password.length > 0
        ) {
            //fetch login from the API
            const server_response = await ajax.loginUser(username, password);

            if (server_response.status === "OK") {

                // this.setState({
                //   login: true
                // }, () => {
                //   setTimeout(() => {
                //     this.setState({
                //       login: false
                //     });
                //   }, 3000);
                // });

                localStorage.setItem('pmisdocs@user', server_response.details.content.access_token);

                // decode the token to access some variables for use
                const access_token = localStorage.getItem('pmisdocs@user');
                const decorded_token = functions.parseJwt(access_token);

                const role_id = decorded_token['data']['role_id'];
                const is_secure = decorded_token['data']['is_secure'];

                if (is_secure === "0") {
                    this.props.history.push('/password-reset');
                    window.location.reload();



                } else {
                    this.props.history.push('/');
                    window.location.reload();

                }
            } else {

                this.setState({
                    // login: false,
                    info: <AlertError message={server_response.details.message} />
                })

            }
        } else {
            this.setState({
                info: <AlertError message={dictionary._enterUsername} />,
                loading: ''
            })
        }


    }

    onChangeUsername = (event) => {
        this.setState({ username: event.target.value });
    }

    onChangePassword = (event) => {
        this.setState({ password: event.target.value });
    }



    render() {
        // const login = this.state.login

        return (
            // <div class="page main-signin-wrapper">

            <div className="body d-flex flex-column">
                <div className="m-auto">
                    <div className="container-fluid">
                        <div className="card login-card">
                            <div className="card-body">
                                {/* <h2 className="login-card-title">Logo</h2> */}
                                <div className="text-center mb-5 mt-2">
                                    <img src={process.env.PUBLIC_URL + "../../images/logo-normal-docs2.svg"} className="header-brand-img" alt="logo" />
                                    <img src={process.env.PUBLIC_URL + "../../images/logo-normal-docs2.svg"} className="header-brand-img theme-logos" alt="logo" />
                                </div>
                                <p className="login-card-description">Sign into your account</p>
                                {this.state.info}
                                <form method="post" onSubmit={this.onPressLogin}>
                                    <div className="form-group">
                                        <label htmlFor="username" className="sr-only">Username</label>
                                        <input
                                            type="text"
                                            name="username"
                                            id="username"
                                            className="form-control"
                                            placeholder="Username"
                                            onChange={this.onChangeUsername} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password" className="sr-only">Password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            className="form-control"
                                            placeholder="Password"
                                            onChange={this.onChangePassword} />
                                    </div>
                                    <div className="form-options-wrapper">
                                        <div className="custom-control custom-checkbox login-card-check-box">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={this.showPassword} />
                                            <label className="custom-control-label" htmlFor="customCheck1">Show password</label>
                                        </div>
                                        <a href="/password-reset" className="text-reset">Forgot password?</a>
                                    </div>

                                    <button className="btn ripple btn-block login-btn">
                                        {/* <span aria-hidden="true" class="spinner-border spinner-border-sm login-btn-spinner" role="status"></span> */}
                                        Sign In
                                    </button>

                                    {/* {!login && <ActivityLoader />} */}

                                    {/* <button class="btn ripple btn-primary" type="button">
                    <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span> <span class="sr-only">Loading...</span>
                  </button> */}
                                    {/* <button class="btn ripple btn-secondary" disabled type="button">
                    <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span> Loading...
                  </button> */}

                                </form>
                                <p className="login-card-footer-text">Can not access your account? <a href="#!" className="text-reset">Request ADMIN here</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div>
          <div className="container">
            <div className="footer-content-wrapper">
              <p className="mb-sm-0">Copyright 2020  Thrivetecug.co.ug</p>
              <nav>
                <a href="#!">Terms of use.</a>
                <a href="#!">Privacy policy</a>
              </nav>
            </div>
          </div>
        </div> */}
            </div>


            // </div>


        );

    }
}

export default Login;
