// import apiCall from "./apiCall";
import mainApiCall from "./apiMainCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {


    async logOnline() {

        let data = {
            "device": "BROWSER"
        }

        const response = await mainApiCall("log/online", data);
        return response;
    },



}