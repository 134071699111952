import React from 'react';
import dictionary from '../../utils/dictionary';
import AlertError from '../Common/AlertError';
import TextInput from '../Common/TextInput';
import ActivityLoader from '../Common/ActivityLoader';

import { EditorState, convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
import { Editor } from '@tinymce/tinymce-react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

class PopAddDescription extends React.Component {

    state = {
        toast: false,
        modal: '',
        loading: false,

        title: "",
        description: EditorState.createEmpty(),
        error: "",
        htmlDescription: "",
        info: "",
        editorRef: React.createRef()

    }

    onChangeTitle = (event) => {
        this.setState({
            title: event.target.value
        })
    }


    onEditorStateChange = (description) => {
        this.setState({
            description: description,
            htmlDescription: draftToHtml(convertToRaw(this.state.description.getCurrentContent())),
        })
    }


    addDetails = async () => {
        const { htmlDescription } = this.state
        if (this.props.hasSubtitle) {
            if (this.props.isMain) {
                this.props.addDescription(htmlDescription)
            } else {
                this.props.addSubtitleDescription(this.props.subtitleID, htmlDescription)
            }
        } else if (!this.props.hasSubtitle) {
            this.props.addDescription(htmlDescription)
        }
    }

    // onConfirm = (event) => {
    //     event.preventDefault();
    //     const { titleName, description } = this.state;
    //     if (titleName.length > 0) {
    //         this.setState({
    //             loading: true
    //         }, () => {
    //             this.props.onConfirm(titleName, description);
    //         })

    //     } else {
    //         this.setState({
    //             info: ''
    //         }, () => this.setState({
    //             info: <AlertError message={dictionary._requireTitleText} />
    //         })
    //         )
    //     }
    // }

    handleEditorChange = (content, editor) => {
        this.setState({
            htmlDescription: content + '',
        })
    }


    render() {

        const { description } = this.state;

        return (
            <div className="modal" id="modal_add_description">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">

                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            {/* <h6 className="modal-title">Create New Title</h6> */}
                            <h6 className='modal-title'>{`Start writing about '${this.props.subtitle ? this.props.subtitle : this.props.mainTitle}' topic:`}</h6>

                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>

                        <div className="modal-body tx-center pd-y-20 pd-x-20 overflow-hidden scrollable">
                            {this.state.info}

                            {/* <form method="post" onSubmit={this.onConfirm}>
                                <TextInput
                                    label="New title text"
                                    type="text"
                                    onChange={() => this.onChangeTitleText}
                                    value={this.state.titleName}
                                />
                            </form> */}

                            {this.props.noSubDocs &&
                                <p className='d-flex align-items-center mb-3 bg-info-transparent p-3'>
                                    <span><i className="mr-3 fs-30 text-info fas fa-info-circle"></i></span>
                                    <span>
                                        To create sub-pages for this specific topic in your document, click the <span><i className="fs-20 text-primary fas fa-plus"></i></span> icon just next to the title you have created. If you are not interested in adding any subtitles, you can proceed and add details to the topic in your document in the text editor below.
                                    </span>
                                </p>
                            }

                            <div className='w-editor'>

                                {this.state.info}


                                {/* // NEW EDITOR FROM TINYMCE */}
                                <Editor
                                    ref={this.editorRef}
                                    apiKey='dymbtkpqk0fp77fkrkw5p8m8ka3ap3wflgq6zyz2tllskm0v'
                                    init={{
                                        height: 500,
                                        // menubar: false,
                                        // plugins: 'advlist preview anchor fullscreen insertdatetime code help ai tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss',
                                        plugins: 'accordian advlist preview anchor fullscreen insertdatetime code help anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount directionality',
                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                        tinycomments_mode: 'embedded',
                                        tinycomments_author: 'Author name',
                                        mergetags_list: [
                                            { value: 'First.Name', title: 'First Name' },
                                            { value: 'Email', title: 'Email' },
                                        ],
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                                    }}
                                    initialValue="<p>Write something here...</p>"
                                    onEditorChange={this.handleEditorChange}
                                />


                                {/* // NEW EDITOR FROM TINYMCE */}

                                {/* <h5 className='mb-3'>{`Start writing about '${this.props.subtitle ? this.props.subtitle : this.props.mainTitle}' topic:`}</h5> */}

                                {/* <Editor
                                    editorState={description}
                                    wrapperClassName="wrapper-class overflow-hidden"
                                    editorClassName="editor-class inner-editor"
                                    toolbarClassName="toolbar-class w-75"
                                    onEditorStateChange={this.onEditorStateChange}
                                    placeholder="Start documenting your component"
                                /> */}

                                {this.state.error && <div>{this.state.error}</div>}

                            </div>
                            {/* <button className="btn ripple btn-info" onClick={this.addDetails}>
                                Save changes
                            </button> */}
                        </div>

                        <div className="modal-footer">
                            {!this.state.loading && <>
                                {/* <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Confirm Title</button> */}
                                <button className="btn ripple btn-primary" type="button" onClick={this.addDetails}>Save</button>
                                <button className="btn ripple btn-secondary" type="button" onClick={this.onClose} data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>

                </div>
            </div>
        )

    }
}

export default PopAddDescription;
