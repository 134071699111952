import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CodeBlock from '../../Components/CodeBlock';
// import Footer from '../../Components/Common/Footer';
import LeftSideBar from '../../Components/Common/LeftSideBar';
import PageHeader from '../../Components/Common/PageHeader';
// import RightSideBar from '../../Components/Common/RightSideBar';
// import TitleValueCard from '../../Components/Common/TitleValueCard';
import TopBar from '../../Components/Common/TopBar';
// import ConfigMenu from '../../Components/ConfigMenu';
// import functions from '../../utils/functions';
// import Code from './Code';
import ProjectMenu from './ProjectMenu';



const codeString1 = `sudo apt update
sudo apt install apache2
`;

const codeString2 = `sudo apt update`;

const codeString3 = `sudo apt install mysql-server`;

const codeString4 = `sudo mysql_secure_installation`;

const codeString5 = `sudo mysql`;

const codeString6 = `mysql -u root -p`;

const codeString7 = `mysql > CREATE USER 'pmis'@'localhost' IDENTIFIED BY 'EnterNewPassword';`;

const codeString8 = `mysql > GRANT ALL PRIVILEGES ON * . * TO 'pmis’@'localhost';
mysql> FLUSH PRIVILEGES;`;

const codeString9 = `mysql > CREATE DATABASE db_parking;`;

const codeString10 = `sudo apt install php libapache2-mod-php php-mysql`;

const codeString11 = `sudo mkdir /var/www/parking.com`;

const codeString12 = `sudo mkdir /var/www/streetparking.com`;

const codeString13 = `sudo apt-get install php-curl`;

const codeString14 = `sudo nano /var/www/parking.com/[CODE_FOLDER]/.env`;

const codeString15 = `DATABASE_NAME=db_parking
DATABASE_USER=pmis_user
DATABASE_PASSWORD=[ENTER PASSWORD HERE]`;

const codeString16 = `sudo nano /etc/apache2/sites-available/parking.com.conf`;

const APACHE_LOG_DIR = 'APACHE_LOG_DIR';

const codeString17 = `<VirtualHost *:80>
        ServerAdmin webmaster@localhost
        ServerName 
        ServerAlias www.your_domain
        DocumentRoot /var/www/parking.com/[CODE_FOLDER_NAME]
        ErrorLog ${APACHE_LOG_DIR}/error.log
        CustomLog ${APACHE_LOG_DIR}/access.log combined
</VirtualHost>`;

const codeString18 = `bind_address = 64.227.40.28
Server_id = 1`;

const codeString19 = `log_bin = /var/log/mysql/mysql-bin.log
log_bin_index =/var/log/mysql/mysql-bin.log.index
relay_log = /var/log/mysql/mysql-relay-bin
relay_log_index = /var/log/mysql/mysql-relay-bin.index`;

const codeString20 = `sudo systemctl restart mysql`;

const codeString21 = `mysql> CREATE USER 'pmis_remote'@'64.227.40.28' IDENTIFIED BY 'PMIS@2020%';
mysql> GRANT REPLICATION SLAVE ON *.* TO 'pmis_remote '@'64.227.40.28';
mysql> FLUSH PRIVILEGES;

mysql> CREATE USER 'pmis_m7'@'45.221.11.60' IDENTIFIED BY 'PMIS@2020%';
mysql> GRANT REPLICATION SLAVE ON *.* TO 'pmis_m7 '@'45.221.11.60';
mysql> FLUSH PRIVILEGES;

mysql> CREATE USER'm7_replica'@'45.221.11.60' IDENTIFIED WITH'mysql_native_password' BY'PMIS@2020%';
mysql> GRANT REPLICATION SLAVE ON *.* TO'm7_replica'@'45.221.11.60';
mysql> GRANT ALL PRIVILEGES ON *.* TO 'm7_replica'@'45.221.11.60';
mysql> FLUSH PRIVILEGES;

mysql> SHOW MASTER STATUS\G`;

const codeString22 = `bind-address = 192.168.0.187`;

const codeString23 = `log_bin = /var/log/mysql/mysql-bin.log
log_bin_index =/var/log/mysql/mysql-bin.log.index
relay_log = /var/log/mysql/mysql-relay-bin
relay_log_index = /var/log/mysql/mysql-relay-bin.index`;

const codeString24 = `sudo systemctl restart mysql`;

const codeString25 = `mysql> STOP SLAVE;`;

const codeString26 = `mysql> CHANGE MASTER TO MASTER_HOST ='64.227.40.28', MASTER_USER ='m7_replica', MASTER_PASSWORD ='PMIS@2020%', MASTER_LOG_FILE = 'mysql-bin.000009', MASTER_LOG_POS = 312952;`;

const codeString27 = `mysql> START SLAVE;`;

const codeString28 = `mysql> CREATE USER 'pmis_remote'@'192.168.0.187' IDENTIFIED BY 'PMIS@2020%';
mysql> GRANT ALL PRIVILEGES ON *.* TO 'pmis_remote'@'192.168.0.187';
mysql> FLUSH PRIVILEGES`;

const codeString29 = `mysql> CREATE USER 'pmis_super'@'%' IDENTIFIED BY 'PMIS@2020%';
mysql> GRANT ALL PRIVILEGES ON *.* TO 'pmis_super’@'%';
mysql> FLUSH PRIVILEGES;

mysql> mysql -h 45.221.11.60 -P 3306 -u pmis_remote -p db_pre_pmis
mysql> GRANT ALL ON db_pre_pmis.* to 'pmis_remote'@'10.0.1.2' IDENTIFIED BY 'PMIS@2020%';
mysql> GRANT ALL PRIVILEGES ON db_pre_pmis.* TO 'pmis_remote'@'%';
mysql> FLUSH PRIVILEGES;

mysql> mysql -h 10.110.0.2 -P 3306 -u pmis -p`;

const codeString30 = `sudo openssl req -new -newkey rsa:2048 -nodes -out 192_168_0_187.csr -keyout 192_168_0_187.key -subj "/C=UG/ST=Kampala/L=Kampala/O=Multiplex/OU=Street Parking/CN=192.168.0.187"`;

const codeString31 = `sudo openssl x509 -req -days 1825 -in 192_168_0_187.csr -signkey 192_168_0_187.key -out 192_168_0_187.crt`;

const codeString32 = `sudo cat 192_168_0_187.key 192_168_0_187.crt >> 192_168_0_187.pem`;

const codeString33 = `sudo a2enmod ssl
sudo a2enmod headers`;

const codeString34 = `sudo a2enconf ssl-params`;

const codeString35 = `sudo a2ensite default-ssl`;

const codeString36 = `sudo apache2ctl configtest`;

const codeString37 = `sudo systemctl restart apache2`;

const codeString38 = `curl -sL https://deb.nodesource.com/setup_14.x | sudo bash -`;

const codeString39 = `sudo apt install nodejs -y`;

const codeString40 = `node -v`;

const codeString41 = `npm install -g yarn`;

const codeString42 = `ps aux | grep apache | wc -l
/etc/apache2/mods-enabled/mpm_prefork.conf`;




class InstallationDocument extends Component {


    state = {
        copy: false
    }

    // onClickCopy = () => {
    //     navigator.clipboard.writeText(codeString)
    //     this.setState({
    //         copy: true
    //     })
    //     setTimeout(() => {
    //         this.setState({
    //             copy: false
    //         })
    //     }, 3000)
    // }

    componentDidMount() { }

    render() {

        return (
            <div className="page">
                <LeftSideBar
                    onClickDeleteTitle={() => { }}

                />
                <div className="main-content side-content pt-0" >
                    <TopBar />
                    <div className="container-fluid">

                        <PageHeader
                            title="Project Documentation"
                            previousPageTitle="Getting Started"
                            back="/"
                        // options={{
                        //       "add": () => this.onClickAdd(),
                        //       "edit": () => this.onClickEdit(),
                        //       "delete": () => this.onClickDelete()
                        //   }}
                        />

                        <div className="row">
                            <div className="col-xl-9 col-lg-12">

                                <div className="card custom-card">

                                    <div className="card-body">

                                        <section className="table-responsive allView-docs scroll-widget overflow-auto mt-2 px-3 w-100">
                                            <section className="wrapper__content">
                                                <div className="content__default">

                                                    {/* <p>
                                                The Parking Management System (PMIS) is an information system that manages the parking in the kampala streets and other areas partaining in parking outside kampala. Its major role is to provide real time information about what is taking place during parking activity of the people and their cars on the streets of kampala. This information provided is later used for important information generation that will govern the day to day activities or in this case street parking on the kampala streets. This platform is also used for automating and creating of reports that will in the end be used for information generation.
                                            </p> */}

                                                    <h2 style={{ marginBottom: "25px" }}>PMIS Installation manual</h2>

                                                    <div className="content__default">
                                                        <p>
                                                            The purpose of this module is to guide the IT team on how to set up a live environment for the operation of the PMIS. The phase takes you through a step-by-step process of the PMIS code deployment within a server environment.
                                                        </p>

                                                        <h4 style={{ margin: "37px 0 25px" }}>
                                                            <a href="#get-started" id="prerequisite" className="header-anchor">#</a> Prerequisite
                                                        </h4>

                                                        <p>

                                                            This document assumes prior knowledge of working with Ubuntu, virtual hosts, and MySQL. You don’t need to be an expert but at least some basic knowledge will give some an upper hand in mastering this entire process.
                                                        </p>

                                                        <h4 style={{ margin: "37px 0 25px" }}>
                                                            <a href="#scope" id="scope" className="header-anchor">#</a> Scope
                                                        </h4>

                                                        <p>

                                                            This guide strictly covers PMIS code deployment and not mobile app deployment. The document is a guide for setting up the backbone of the entire PMIS system and by the completion of this guide, the system admin should be able to login to the admin dashboard.
                                                        </p>

                                                        <h4 style={{ margin: "37px 0 25px" }}>
                                                            <a href="#environment-setup" id="environment-setup" className="header-anchor">#</a> Environment Setup
                                                        </h4>

                                                        <p>
                                                            PMIS is a web application and the following steps will guide you on how to set up a web application environment. You can skip this section if you are already running an apache - mysql web application.
                                                        </p>

                                                        <h5 id="install-ubuntu" style={{ margin: "37px 0 25px" }}>
                                                            Step 1: Install Ubuntu
                                                        </h5>

                                                        <p>
                                                            To install ubuntu on the <b>local server</b>, follow this link to download the latest version: <a href="https://ubuntu.com/download/desktop" className="">Download Ubuntu Desktop | Download | Ubuntu </a> <br /> If you are running a <b>VPS</b> in this case <b>digital ocean</b>, create a new droplet and select ubuntu as the operating system. Ubuntu will be installed automatically.
                                                        </p>

                                                        <h5 id="install-apache" style={{ margin: "37px 0 25px" }}>
                                                            {/* <a href="#get-started" className="header-anchor">#</a>  */}
                                                            Step 2: Install Apache
                                                        </h5>

                                                        <p>
                                                            The Apache web server is a popular method for serving websites on the internet. As of 2019, it is estimated to serve 29% of all active websites and it offers robustness and flexibility for developers. Using Apache, an administrator can set up one server to host multiple domains or sites off of a single interface or IP by using a matching system.
                                                            <br />
                                                            <br />
                                                            To install apache run the following commands in your terminal
                                                        </p>

                                                        <CodeBlock codeString={codeString1} title="sql code" />

                                                        <h5 id="install-mysql" style={{ margin: "37px 0 25px" }}>
                                                            {/* <a href="#get-started" className="header-anchor">#</a>  */}
                                                            Step 3: Install MySQL
                                                        </h5>

                                                        <p>
                                                            MySQL is an open-source database management system, commonly installed as part of the popular LAMP (Linux, Apache, MySQL, PHP/Python/Perl) stack. It implements the relational model and uses Structured Query Language (better known as SQL) to manage its data.
                                                            <br />
                                                            <br />
                                                            On Ubuntu 20.04, you can install MySQL using the APT package repository. At the time of this writing, the version of MySQL available in the default Ubuntu repository is version 8.0.19.
                                                            <br />
                                                            <br />
                                                            To install it, update the package index on your server if you’ve not done so recently:
                                                        </p>

                                                        <CodeBlock codeString={codeString2} title="sql code" />

                                                        <p>
                                                            Then install the mysql-server package:
                                                        </p>

                                                        <CodeBlock codeString={codeString3} title="sql code" />

                                                        <p>
                                                            Run the security script with sudo:
                                                        </p>

                                                        <CodeBlock codeString={codeString4} title="sql code" />

                                                        <p>
                                                            This will take you through a series of prompts where you can make some changes to your MySQL installation’s security options. The first prompt will ask whether you’d like to set up the Validate Password Plugin, which can be used to test the password strength of new MySQL users before deeming them valid.
                                                            <br />
                                                            <br />
                                                            Regardless of whether you choose to set up the Validate Password Plugin, the next prompt will be to set a password for the MySQL root user. Enter and then confirm a secure password of your choice
                                                            <br />
                                                            <br />
                                                            You now have MySQL user installed!
                                                        </p>

                                                        <h5 id="setting-up-database" style={{ margin: "37px 0 25px" }}>
                                                            {/* <a href="#get-started" className="header-anchor">#</a>  */}
                                                            Step 4: Setting up the database
                                                        </h5>

                                                        <p>
                                                            At this point you have MySQL installed. The next step is to set up a PMIS user with all privileges - in your terminal type the following commands:
                                                        </p>

                                                        <CodeBlock codeString={codeString5} title="sql code" />

                                                        <p>
                                                            <b>Note</b>: If you installed MySQL with another tutorial and enabled password authentication for root, you will need to use a different command to access the MySQL shell. The following will run your MySQL client with regular user privileges, and you will only gain administrator privileges within the database by authenticating:
                                                        </p>

                                                        <CodeBlock codeString={codeString6} title="sql code" />

                                                        <p>
                                                            Once you have access to the MySQL prompt, you can create a new user with a CREATE USER statement. These follow this general syntax:
                                                        </p>

                                                        <CodeBlock codeString={codeString7} title="sql code" />

                                                        <p>
                                                            After CREATE USER, you specify a username. This is immediately followed by an @ sign and then the hostname from which this user will connect. If you only plan to access this user locally from your Ubuntu server, you can specify localhost. Wrapping both the username and host in single quotes isn’t always necessary, but doing so can help to prevent errors.
                                                            <br />
                                                            <br />
                                                            After creating your new user, you can grant them the appropriate privileges. The general syntax for granting user privileges is as follows:
                                                        </p>

                                                        <CodeBlock codeString={codeString8} title="sql code" />

                                                        <p>
                                                            At this stage, you can now create the database where all the PMIS data will be stored by running the following command.
                                                        </p>

                                                        <CodeBlock codeString={codeString9} title="sql code" />

                                                        <h5 id="install-php" style={{ margin: "37px 0 25px" }}>
                                                            {/* <a href="#get-started" className="header-anchor">#</a>  */}
                                                            Step 4: Install php
                                                        </h5>

                                                        <p>
                                                            PHP is the component of your setup that will process code to display dynamic content. It can run scripts, connect to your MySQL databases to get information, and hand the processed content over to your web server so that it can display the results to PMIS users.
                                                            <br />
                                                            <br />
                                                            Run the following command to install php and other dependencies:
                                                        </p>

                                                        <CodeBlock codeString={codeString10} title="sql code" />

                                                        <h5 id="php-directories" style={{ margin: "37px 0 25px" }}>
                                                            {/* <a href="#get-started" className="header-anchor">#</a>  */}
                                                            Step 5: Setting up PMIS directories
                                                        </h5>

                                                        <p>
                                                            At this step, the environment setup is complete and what is left is to migrate the code from either a local computer or a remote repository. In both cases, we need to set up a directory where the PMIS code will reside.
                                                        </p>

                                                        <ul className='ml-4'>
                                                            <li>
                                                                <p>
                                                                    Create a new site named <Link to="http://parking.com">Parking.com</Link> in the www directory: this will house the PHP backend.
                                                                </p>

                                                                <CodeBlock codeString={codeString11} title="sql code" />
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    Create another site named <Link to="http://streetparking.com">Parking.com</Link> in the www directory: this will hold the react frontend.
                                                                </p>

                                                                <CodeBlock codeString={codeString12} title="sql code" />
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    Now that we have both the backend and frontend directories in place, let’s start the code migration!
                                                                    <br />
                                                                    <br />
                                                                    Install curl:
                                                                </p>

                                                                <CodeBlock codeString={codeString13} title="sql code" />
                                                            </li>
                                                        </ul>

                                                        <h5 id="migrating-code" style={{ margin: "37px 0 25px" }}>
                                                            {/* <a href="#get-started" className="header-anchor">#</a>  */}
                                                            Step 6: Migrating the code
                                                        </h5>

                                                        <p>
                                                            There are two categories of code to migrate i.e. the react frontend code and the php backend code. All these must be put in the respective directories created in Step 5 above. The code can be downloaded from the github repositories (not disclosed here).
                                                            <br />
                                                            <br />
                                                            <b>Note</b>: The react frontend code should be compiled using the “yarn build” command to obtain a build folder. It’s the build folder that is migrated into the frontend directory on the server.
                                                        </p>

                                                        <h5 id="configuring-backend" style={{ margin: "37px 0 25px" }}>
                                                            {/* <a href="#get-started" className="header-anchor">#</a>  */}
                                                            Step 7: Configuring the backend
                                                        </h5>

                                                        <p>
                                                            After migrating the backend, we must configure it in accordance with the server environment we have already set up. This should be done using the .env file located in the root directory of the code. To create/modify the .env file, follow the steps below:
                                                        </p>

                                                        <CodeBlock codeString={codeString14} title="sql code" />

                                                        <p>
                                                            Add or modify the following lines of code in the file as shown below:
                                                        </p>

                                                        <CodeBlock codeString={codeString15} title="sql code" />

                                                        <p>
                                                            Save changes and exit
                                                            <br />
                                                            <br />
                                                            <b>Warning</b>: The .env file must be confidential as it contains system parameters like database password, aggregator credentials and other environment configuration parameters.
                                                        </p>

                                                        <h5 id="apache-hosts" style={{ margin: "37px 0 25px" }}>
                                                            {/* <a href="#get-started" className="header-anchor">#</a>  */}
                                                            Step 8: Configuring the apache virtual hosts
                                                        </h5>

                                                        <p>
                                                            At this step everything is set and now we need to configure the server in order to access the system via a domain like <Link to="http://www.streetparking.thrivetecdev.com">http://www.streetparking.thrivetecdev.com</Link>
                                                            <br />
                                                            <br />
                                                            It is important to note that each of the directories must have a separate domain to access it and to do this, follow the steps below:
                                                        </p>

                                                        <ul className='ml-4'>
                                                            <li>
                                                                <p>
                                                                    Create a backend configuration file using the following command
                                                                </p>

                                                                <CodeBlock codeString={codeString16} title="sql code" />
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    Copy and paste the following code snippet and modify it where necessary.
                                                                </p>

                                                                <CodeBlock codeString={codeString17} title="sql code" />
                                                            </li>
                                                            <li>
                                                                <p>Work on Mirroring</p>

                                                                <ul className='ml-4'>
                                                                    <li>
                                                                        <p>
                                                                            Begin by setting up master
                                                                        </p>

                                                                        <CodeBlock codeString={codeString18} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            Add the following at the bottom of the mysqld.conf.
                                                                        </p>

                                                                        <CodeBlock codeString={codeString19} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            Restart mysql
                                                                        </p>

                                                                        <CodeBlock codeString={codeString20} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            Create a New User for Replication on Master Node.
                                                                        </p>

                                                                        <CodeBlock codeString={codeString21} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            Configure the MySQL Slave Server
                                                                        </p>

                                                                        <CodeBlock codeString={codeString22} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            Again, paste the lines below at the very end of the configuration file.
                                                                        </p>

                                                                        <CodeBlock codeString={codeString23} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            Next restart MySQL server on slave node.
                                                                        </p>

                                                                        <CodeBlock codeString={codeString24} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            in this step, you will need to make some configuration that will allow the slave server to connect to the master server. But first, stop the slave threads as shown.
                                                                        </p>

                                                                        <CodeBlock codeString={codeString25} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            To allow the slave server to replicate the Master server, run the command.
                                                                        </p>

                                                                        <CodeBlock codeString={codeString26} title="sql code" />
                                                                    </li>

                                                                    <li>
                                                                        <p>
                                                                            If you are keen enough, you will observe that we’ve used the mysql-bin.00001 value and position ID 130576 earlier displayed after creating the slave replication user.
                                                                            <br />
                                                                            <br />
                                                                            Additionally, the Master server’s IP address, replication user and password have been used.
                                                                            <br />
                                                                            <br />
                                                                            Later, start the thread you had earlier stopped.
                                                                        </p>

                                                                        <CodeBlock codeString={codeString27} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            Verify the MySQL Master-Slave Replication
                                                                        </p>

                                                                        <ul className='ml-4'>
                                                                            <li>
                                                                                <p>
                                                                                    Option 1
                                                                                </p>

                                                                                <CodeBlock codeString={codeString28} title="sql code" />
                                                                            </li>
                                                                            <li>
                                                                                <p>
                                                                                    Option 2
                                                                                </p>

                                                                                <CodeBlock codeString={codeString29} title="sql code" />
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <p>Work on Certification</p>

                                                                <ul className='ml-4'>
                                                                    <li>
                                                                        <p>
                                                                            Generate CSR
                                                                        </p>

                                                                        <CodeBlock codeString={codeString30} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            Use the csr to generate the certificate
                                                                        </p>

                                                                        <CodeBlock codeString={codeString31} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            Generate SSL
                                                                        </p>

                                                                        <CodeBlock codeString={codeString32} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            Modify the default-ssl.conf in sites-available to include certificate paths
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            Enable the mod_ssl and mod_headers modules:
                                                                        </p>

                                                                        <CodeBlock codeString={codeString33} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            Enable reading of the SSL configuration created earlier: (Optional: seek more clarity)
                                                                        </p>

                                                                        <CodeBlock codeString={codeString34} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            Enable the default SSL Virtual Host:
                                                                        </p>

                                                                        <CodeBlock codeString={codeString35} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            Check that you have not made syntax errors in the Apache configuration files:
                                                                        </p>

                                                                        <CodeBlock codeString={codeString36} title="sql code" />
                                                                    </li>
                                                                    <li>
                                                                        <p>
                                                                            Restart apache
                                                                        </p>

                                                                        <CodeBlock codeString={codeString37} title="sql code" />
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>

                                                        <h5 id="installing-nodejs" style={{ margin: "37px 0 25px" }}>
                                                            {/* <a href="#get-started" className="header-anchor">#</a>  */}
                                                            Step 9: Installing NodeJS
                                                        </h5>

                                                        <p>
                                                            Add the Node.js 14 (current stable version) PPA to your Ubuntu system
                                                        </p>

                                                        <CodeBlock codeString={codeString38} title="sql code" />

                                                        <p>
                                                            Install node
                                                        </p>

                                                        <CodeBlock codeString={codeString39} title="sql code" />

                                                        <p>
                                                            Finally, check the current active version of Node.js
                                                        </p>

                                                        <CodeBlock codeString={codeString40} title="sql code" />

                                                        <p>
                                                            install Yarn package manager on your system
                                                        </p>

                                                        <CodeBlock codeString={codeString41} title="sql code" />

                                                        <h5 id="performance-optimization" style={{ margin: "37px 0 25px" }}>
                                                            {/* <a href="#get-started" className="header-anchor">#</a>  */}
                                                            Step 10: Performance optimization
                                                        </h5>

                                                        <p>
                                                            Checking resources:
                                                        </p>

                                                        <CodeBlock codeString={codeString42} title="sql code" />
                                                    </div>

                                                </div>
                                            </section>
                                        </section>

                                    </div>

                                </div>

                            </div>

                            <ProjectMenu
                                sider
                                installation
                            />
                            {/* <Code /> */}
                        </div>
                    </div>
                </div>
                {/* <RightSideBar /> */}

            </div>
        );
    }
}

export default InstallationDocument;
