import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

export const RenderAddIcon = ({ onClickAdd, extraClasses, tipText }) => {
    return (
        <Tippy content={tipText}>
            {/* <span className={` ${extraClasses} d-flex align-items-center rounded ml-0 py-2`} onClick={onClickAdd}>
                    <i className="fas fa-plus tx-10 m-0 order"></i>
                </span> */}

            <span onClick={onClickAdd} className={` ${extraClasses}rounded add-icon-cursor d-flex align-items-center justify-content-center w-5`}><i className="fs-15 p-2 text-primary fas fa-plus"></i></span>
        </Tippy>
    );

}


