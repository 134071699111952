import React, { createContext, useEffect, useState } from "react";
import ajaxPmisDocs from "../utils/remote/ajaxPmisDocs";

const DocumentationContext = createContext({});


export const DocumentationConsumer = DocumentationContext.Consumer;

export const DocumentationProvider = (props) => {

    const [docList, setDocList] = useState("");
    const [singleDocInfo, singleTitleList] = useState([]);
    const [titleID, setTitleID] = useState("");
    const [subtitleID, setSubTitleID] = useState("");
    const [subtitleInfo, setSubTitleInfo] = useState("");





    useEffect(() => {
        listAllTitles()
    }, [])

    useEffect(() => {
        listSingleTitle()
    }, [titleID])

    useEffect(() => {
        listSubtitleInfo()
    }, [subtitleID])




    const listAllTitles = async () => {
        const server_response = await ajaxPmisDocs.listAllDocs();
        if (server_response.status === 'OK') {
            setDocList(server_response.details.content)
        }else if(server_response.status ==="fail"){
            setDocList("404")
        }
        else {
            setDocList("fail")
        }

    }


    const listSingleTitle = async () => {
        const server_response = await ajaxPmisDocs.getTitleByID(titleID);
        if (server_response.status === 'OK') {
            singleTitleList(server_response.details.content)
        }
        else {
            singleTitleList("404")
        }

    }

    const listSubtitleInfo = async () => {
        const server_response = await ajaxPmisDocs.getSubtitleInfo(subtitleID);
        if (server_response.status === 'OK') {
            setSubTitleInfo(server_response.details.content)
        }
        else {
            setSubTitleInfo("404")
        }

    }

    return (
        <DocumentationContext.Provider
            value={{
                docList,
                listAllTitles,
                titleID,
                setTitleID,
                singleDocInfo,
                setSubTitleID,
                subtitleInfo,
            }}
        >
            {props.children}
        </DocumentationContext.Provider>
    );
}


export default DocumentationContext;