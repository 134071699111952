import React, { createContext, useState } from "react";

const EditorContext = createContext({});


export const EditorConsumer = EditorContext.Consumer;

export const EditorProvider = (props) => {

    const [editorDescription, setDescription] = useState(1);

    // useEffect(() => {
    //     setDescription(editorDescription)
    // }, [editorDescription])



    return (
        <EditorContext.Provider
            value={{
                editorDescription,
                setDescription
            }}
        >
            {props.children}
        </EditorContext.Provider>
    );
}


export default EditorContext;