import React, { createRef } from 'react';
import ActivityLoader from '../Common/ActivityLoader';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
import { Editor } from '@tinymce/tinymce-react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
// import htmlToDraft from 'draft-js-import-html';



class PopEditDescription extends React.Component {

    constructor(props) {
        super(props);

        // Create a ref to store the editor instance
        this.editorRef = React.createRef();
        this.state = {
            title: "",
            description: EditorState.createEmpty(),
            error: "",
            htmlDescription: "",
            info: "",
            htmlData: this.props.description
        }
    }





    onChangeTitle = (event) => {
        this.setState({
            title: event.target.value
        })
    }


    onEditorStateChange = (description) => {
        this.setState({
            description: description,
            htmlDescription: draftToHtml(convertToRaw(this.state.description.getCurrentContent())),
        })
    }

    // getContentAsString = () => {
    //     console.log('string content', this.editorRef.current.getContent())
    //     return this.editorRef.current.getContent();
    // };


    editDescription = async () => {
        const { htmlDescription } = this.state
        if (this.props.hasSubtitle) {
            this.props.editSubtitleDescription(htmlDescription)
        } else if (!this.props.hasSubtitle) {
            this.props.editDescription(htmlDescription)
        }


    }




    handleEditorChange = (content, editor) => {
        this.setState({
            htmlDescription: content + '',
        })
    }

 

    render() {

        const { description } = this.state

        return (
            <div className="modal" id={"modal_edit_description"}>
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content modal-content-demo shadow">

                        <div className="modal-header">
                            <h6 className="modal-title">Edit Description text</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>

                        <div className="modal-body tx-center pd-y-20 pd-x-20 overflow-hidden scrollable">
                            {/* <button aria-label="Close" className="close" data-dismiss="modal" type="button">
                                <span aria-hidden="true">&times;</span>
                            </button> */}
                            <div className='w-editor'>

                                {this.state.info}


                                {/* <h5 className='mb-3 '>Edit desri</h5> */}

                                {/* // NEW EDITOR FROM TINYMCE */}
                                <Editor
                                    ref={this.editorRef}
                                    apiKey='dymbtkpqk0fp77fkrkw5p8m8ka3ap3wflgq6zyz2tllskm0v'
                                    init={{
                                        height: 500,
                                        // menubar: false,
                                        plugins: 'accordian advlist preview anchor fullscreen insertdatetime code help anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount directionality',
                                        toolbar: 'accordian undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat | ltr rtl',
                                        tinycomments_mode: 'embedded',
                                        tinycomments_author: 'Author name',
                                        mergetags_list: [
                                            { value: 'First.Name', title: 'First Name' },
                                            { value: 'Email', title: 'Email' },
                                        ],
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                                    }}
                                    initialValue={this.props.description}
                                    onEditorChange={this.handleEditorChange}
                                    defaultEditorState={description}

                                    editorState={description}
                                />


                                {/* // NEW EDITOR FROM TINYMCE */}


                                {/* <Editor
                                    // editorState={convertToRaw(this.props.description.getCurrentContent())}
                                    // defaultEditorState={convertFromRaw(JSON.parse(post.content))}
                                    defaultEditorState={description}

                                    editorState={description}


                                    wrapperClassName="wrapper-class overflow-hidden"
                                    editorClassName="editor-class inner-editor"
                                    toolbarClassName="toolbar-class w-75"
                                    onEditorStateChange={this.onEditorStateChange}
                                    placeholder="Start documenting your here"
                                /> */}

                                {this.state.error && <div>{this.state.error}</div>}

                            </div>




                            {/* {!this.state.loading &&
                                <button style={{ zIndex: '1000' }} className="btn ripple btn-info pd-x-25 mt-20" type="button" >
                                    Save 
                                </button>
                            } */}

                            {/* {this.state.loading && <ActivityLoader />} */}
                        </div>

                        <div className="modal-footer">
                            {!this.state.loading && <>
                                <button className="btn ripple btn-primary" type="button" onClick={this.editDescription}>Save changes</button>
                                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
                            </>}

                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>
                </div>
            </div>
        )


    }
}

export default PopEditDescription;
