import React from 'react'
import { Link } from 'react-router-dom';
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import $ from 'jquery';

import avatar from "../../assets/images/avatar.svg"



class TopBar extends React.Component {

    state = {
        userId: functions.sessionGuard(),

        username: functions.authToken()["full_name"],
        photo: '',
        roleName: functions.authToken().role_code,
        countTodaysNotifications: '',
        drop: false,
        // viewAsModel: <PopRoleList />
    }




    toggleSideBar = () => {
        let element = document.getElementsByTagName('body');
        if (window.matchMedia('(min-width: 768px)').matches) {
            element[0].classList.toggle('main-sidebar-hide');
        } else {
            element[0].classList.toggle('main-sidebar-show');
        }
    }


    // openDrawer = () => {
    //     setTimeout(() => {
    //         document.getElementById('system_drawer').classList.add('sidebar-open')

    //     }, 100);
    // }


    closeSideBar = () => {
        let element = document.getElementsByTagName('body');
        if (window.matchMedia('(min-width: 768px)').matches) {
            // element[0].classList.toggle('main-sidebar-hide');
            element[0].classList.add('main-sidebar-hide');
        } else {
            //element[0].classList.toggle('main-sidebar-show');
            element[0].classList.add('main-sidebar-show');
        }
    }

    toggleProfileAccess = () => {
        let element = document.getElementById('profile-menu-access');
        element.classList.toggle('show');

    }

    onLogout = () => {
        // const cookies = new Cookies();
        // cookies.remove('pmisdocs@user');
        // window.location.replace('/login')

        localStorage.removeItem('pmisdocs@user');
        window.location.replace('/login');
    }

    logos = () => {
        return (
            <Link className="main-logo d-lg-none" to="/">
                <img src={process.env.PUBLIC_URL + "../../images/parking_f.png"} className="header-brand-img desktop-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + "../../images/parking_f.png"} className="header-brand-img icon-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + "../../images/parking_f.png"} className="header-brand-img desktop-logo theme-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + "../../images/parking_f.png"} className="header-brand-img icon-logo theme-logo" alt="logo" />
            </Link>
        )
    }

    toggleDropdown = (idSelector) => {

        //functions.removeClassFromElements('header-dropdown', 'show') 
        setTimeout(() => {
            document.getElementById(idSelector).classList.toggle('show');

        }, 100);
    }




    onClickSearch = () => {
        $("#mdl-sys-finder").modal('show');
    }

    // togDrop = () => {
    //     this.setState({
    //         drop: true
    //     })
    // }


    render() {

        const USER_BOUNDARY = functions.userBoundary();

        return (
            <>
                <div className="main-header side-header sticky-pin">
                    <div className="container-fluid">
                        <div className="main-header-left">
                            <this.logos />
                            <a className="main-header-menu-icon" href="#" onClick={this.toggleSideBar} id="mainSidebarToggle"><span></span></a>
                        </div>

                        <div className="main-header-right">
                            {/* <div className="dropdown main-profile-menu " id="docs_menu">
                            <a className="nav-link icon" href="#" onClick={() => this.toggleDropdown('docs_menu')}>
                                DOCS
                            </a>

                            <div className="dropdown-menu">
                                <Link className="dropdown-item" to="/documentation/project-docs">
                                    <i className="fe fe-compass"></i> Project Documentation
                                </Link>
                                <Link className="dropdown-item" to="/documentation/api-docs">
                                    <i className="fe fe-power"></i> API Documentation
                                </Link>
                            </div>
                        </div> */}

                            {/* <ViewAs /> */}

                            {/* <TableTools /> */}





                            <div className="dropdown main-profile-menu header-dropdown" id="profile_menu">
                                <a className="main-img-user" href="#" onClick={() => this.toggleDropdown('profile_menu')}>
                                    <img alt="avatar" src={avatar} />
                                </a>

                                <div className="dropdown-menu">
                                    <div className="header-navheading">
                                        <h6 className="main-notification-title">{this.state.username}</h6>
                                        <p className="main-notification-text">{this.state.roleName}</p>
                                    </div>
                                    <Link className="dropdown-item" to="#">
                                        <i className="fe fe-user"></i> My Profile
                                    </Link>

                                  
                                    <Link className="dropdown-item" to="#" onClick={this.onLogout}>
                                        <i className="fe fe-power"></i> Sign Out
                                    </Link>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default TopBar
