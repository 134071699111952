import React from 'react';
import dictionary from '../../utils/dictionary';
import AlertError from '../Common/AlertError';
import TextInput from '../Common/TextInput';
import ActivityLoader from '../Common/ActivityLoader';
class PopAddPageSummary extends React.Component {

    state = {
        toast: false,
        modal: '',
        loading: false,

        heading: "",
        error: "",
        htmlDescription: "",
        info: ""
    }

    onChangeHeading = (event) => {
        this.setState({
            heading: event.target.value
        })
    }


    onClickSave = async () => {
        this.props.onClickSave(this.state.heading)

    }



    render() {

        return (
            <div className="modal" id="modal_add_page_summary">
                <div className="modal-dialog modal-dialog-centered" role="document">

                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            {/* <h6 className="modal-title">Create New Title</h6> */}
                            <h6 className='modal-title'> Add heading to page summary</h6>

                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            {this.state.info}

                            <form method="post" onSubmit={this.onConfirm}>
                                <TextInput
                                    label=" Create Heading"
                                    placeholder="add heading here"
                                    type="text"
                                    onChange={() => this.onChangeHeading}
                                    value={this.state.heading}
                                />
                            </form>

                        </div>

                        <div className="modal-footer">
                            {!this.state.loading && <>
                                {/* <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Confirm Title</button> */}
                                <button className="btn ripple btn-primary" type="button" onClick={this.onClickSave}>Save</button>
                                <button className="btn ripple btn-secondary" type="button" onClick={this.onClose} data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader />}
                        </div>
                    </div>

                </div>
            </div>
        )

    }
}

export default PopAddPageSummary;
