import axios from "axios";
import dictionary from "../dictionary";
import functions from "../../utils/functions";

const ACCESS_TOKEN = localStorage.getItem('pmisdocs@user');
const apiHost = functions.getHost()
const mainApiHost = functions.getMainHost()

const apiMainCall = async (endpoint, data) => {

    try {
        let response = await axios.post(mainApiHost + endpoint, data,
            {
                headers: {
                    'Authorization': 'Bearer ' + ACCESS_TOKEN,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

        // let result = await response.json();
        // return result;
        return response.data
    } catch (error) {
        let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
        return connError;
    }

}


export default apiMainCall