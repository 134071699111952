import React, { Component } from 'react';
// import Footer from '../../Components/Common/Footer';
import LeftSideBar from '../../Components/Common/LeftSideBar';
import PageHeader from '../../Components/Common/PageHeader';
// import RightSideBar from '../../Components/Common/RightSideBar';
// import TitleValueCard from '../../Components/Common/TitleValueCard';
import TopBar from '../../Components/Common/TopBar';
// import { TextEditor } from '../../Components/TextEditor/TextEditor';
// import ConfigMenu from '../../Components/ConfigMenu';
// import functions from '../../utils/functions';
// import Code from './Code';
// import ProjectMenu from './ProjectMenu';
import { createRef } from 'react';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import DashCard from '../../Components/Cards/OperationCards/DashCard'
// import DashElement from '../../Components/Cards/OperationCards/DashElement';

import wallpaper from '../../assets/wallpep2.png';
import { Link } from 'react-router-dom'


class WelcomeDocument extends Component {

    state = {}

    constructor(props) {
        super(props)
        this.componentRef = createRef();
    }


    componentDidMount() { }

    // handlePirnt = useReactToPrint({
    //     content: () => this.componentRef.current,
    //     documentTitle: 'pmis-docs',
    //     onAfterPrint: () => alert('Print Success')
    // })



    CardSection = () => {

        return (
            <>
                <DashCard className="col-sm-6 col-md-6 col-xl-4"
                    value="Install"
                    icon="fas fa-wrench"
                    // href="/enforcement/clamp_transactions"
                    title="Process"
                    color="icon-service bg-success-transparent rounded-circle text-success"
                />
                <DashCard className="col-sm-6 col-md-6 col-xl-4"
                    value="Codebase"
                    icon="fas fa-code"
                    // href="/enforcement/tow_transactions"
                    title="Process"
                    color="icon-service bg-info-transparent rounded-circle text-info"
                />
                <DashCard className="col-sm-6 col-md-6 col-xl-4"
                    value="Configure"
                    icon="fas fa-ban"
                    // href="/enforcement/tow_transactions"
                    title="Process"
                    color="icon-service bg-secondary-transparent rounded-circle text-secondary"
                />
            </>
        )
    }

    CardSection2 = () => {

        return (
            <>
                <DashCard className="col-sm-6 col-md-6 col-xl-3"
                    title="Archievement"
                    icon="fas fa-ban"
                    href="/enforcement/clamp_transactions"
                    value={202}
                    color="icon-service bg-success-transparent rounded-circle text-success"
                />
                <DashCard className="col-sm-6 col-md-6 col-xl-3"
                    title="Towed Vehicles"
                    icon="fas fa-wrench"
                    href="/enforcement/tow_transactions"
                    value={150}
                    color="icon-service bg-info-transparent rounded-circle text-info"
                />
                <DashCard className="col-sm-6 col-md-6 col-xl-3"
                    title="Towed Vehicles"
                    icon="fas fa-wrench"
                    href="/enforcement/tow_transactions"
                    value={150}
                    color="icon-service bg-info-transparent rounded-circle text-info"
                />
                <DashCard className="col-sm-6 col-md-6 col-xl-3"
                    title="Towed Vehicles"
                    icon="fas fa-wrench"
                    href="/enforcement/tow_transactions"
                    value={150}
                    color="icon-service bg-info-transparent rounded-circle text-info"
                />
            </>
        )
    }

    IntroSection = () => {

        return (
            <>
                <div className="col-sm-8 col-md-8 col-xl-8">
                    {/* <Link to="#"> */}
                    <div className="border bg-white dash-element docs-border-radius docs-light-orange  ">
                        <div className=" d-flex justify-content-center ml-1 my-3  p-3">
                            <div className="w-50 main-img-code">
                                {/* <i className={this.props.icon}></i> */}
                                <img src={wallpaper} alt="" />
                            </div>
                            <div className="w-50 ml-4  ">
                                <h4 className="mb-3"> Understanding the PMIS Backbone</h4>
                                <p className="mb-0">
                                    The Parking Management System (PMIS) serves as a crucial information hub, managing parking activities in the streets of Kampala and other designated areas outside the city. Its primary function is to deliver real-time insights into parking activities, both for individuals and their vehicles in Kampala streets. The data collected plays a pivotal role in generating essential information governing day-to-day street parking activities in Kampala.

                                </p>
                            </div>
                        </div>
                    </div>
                    {/* </Link> */}

                    {/* <div className='row'> */}
                    {/* <this.CardSection /> */}

                    {/* <div className="col-12"> */}

                    {/* <PageHeader
                                    title="Support Column"
                                    previousPageTitle="PMIS Documentation"
                                /> */}

                    <div className=" border dash-element docs-border-radius mt-4">

                        <div className="">
                            <section className="card_wrapper__content" ref={el => (this.componentRef = el)}>
                                {/* <h3 className="mb-3">Support Column</h3> */}

                                {/* <p>Incase you want to reach out, contact or get more information</p>

                                        <h4 style={{ margin: "25px 0" }}>
                                            <Link to="#get-started" className="header-anchor">#</Link> Join Us
                                        </h4>
                                        <p>
                                            Join the Multiplex community by downloading the Plex application on google play store so that you can manage your own parking from there. You can also find enough guidance on how to use the application.
                                        </p> */}
                                <h4 style={{ margin: "25px 0" }}>
                                    <Link to="#get-started" className="header-anchor"></Link> Technologies used
                                </h4>
                                <p>
                                    To delve into the functionality of PMIS, it's essential to comprehend the technology at its core: The PMIS employes a number of technologies for its smooth flow and implementation. These include:-

                                </p>
                                {/* <section className="card_wrapper__content" ref={el => (this.componentRef = el)}> */}

                                {/* <h4 style={{ margin: "25px 0" }}>
                                    <Link to="#discover-netlify" className="header-anchor">#</Link> Stacks Employed
                                </h4> */}
                                {/* <p>
                                        The PMIS is employing a number of frameworks for its smooth flow and implementation. In this documenataion we are going to go int detail with the stacks used in the API creation or what is known as the bakend of the System.
                                    </p> */}
                                <p>
                                    <strong>PHP [Vanila]</strong> –PHP, a server-side scripting language, empowers web developers to create dynamic content and interact with databases. Its simplicity, speed, and flexibility have made it a cornerstone in the web development world. To learn more, visit the PHP website.
                                </p>
                                <p>
                                    <strong>SQL [Database]</strong> – Structured Query Language (SQL) is a domain-specific language used for managing data in relational database management systems. It is instrumental in communicating with databases, enabling efficient data management and retrieval.
                                </p>
                            </section>
                        </div>
                    </div>

                    <div className=" border dash-element docs-border-radius mt-4">

                        <div className="">
                            <section className="card_wrapper__content" ref={el => (this.componentRef = el)}>


                                <h4 style={{ margin: "25px 0" }}>
                                    <Link to="#get-started" className="header-anchor"></Link>Need Further Assistance?
                                </h4>
                                <p>
                                    If you require additional support while navigating this documentation, feel free to reach out to the PMIS administration or the Thrivetec technical team. They are dedicated to guiding you through every detail you need to know.

                                    Thank you for choosing PMIS Documentation as your guide to mastering the intricacies of Parking Management Information System. Happy exploring!

                                </p>

                            </section>
                        </div>
                    </div>

                    {/* </div>
                    </div> */}
                </div>

                <div className="col-sm-4 col-md-4 col-xl-4">
                    <Link to="#">
                        <div className="border p-2 bg-white docs-border-radius dash-element">
                            <div className="d-flex ml-1 mt-3 mb-3">
                                {/* <div className={this.props.color}>
                                <i className={this.props.icon}></i>
                            </div> */}
                                <div className="ml-4">
                                    <h3 className="mb-3">Exploring PMIS Components</h3>
                                    <p className="mb-3">
                                        The documentation covers various modules to provide you with a comprehensive understanding of PMIS:
                                    </p>
                                    {/* <p className="mb-0"> */}
                                    <ul className="mb-0">
                                        <li><Link to="/installation-guide" className="dashboard-title"> Installation</Link>
                                            <ul style={{ marginLeft: "35px" }}>
                                                <li> Learn how to install PMIS and set it up for seamless operation.</li>
                                            </ul>

                                        </li>

                                        <li className=''><span className='tx-500'>GitHub Integration</span>
                                            <ul style={{ marginLeft: "35px" }}>
                                                <li> Explore the integration of PMIS with GitHub for version control and collaborative development.</li>
                                            </ul>
                                        </li>
                                        <li> <span className='tx-500'>API Documentation</span>

                                            <ul style={{ marginLeft: "35px" }}>
                                                <li> Gain insights into the PMIS API, understanding how it functions and its endpoints.</li>
                                            </ul>
                                        </li>
                                        <li> <span className='tx-500'>Database Configuration and Structure</span>
                                            <ul style={{ marginLeft: "35px" }}>
                                                <li> Understand the database setup and its organizational structure to facilitate data management.</li>
                                            </ul>

                                        </li>
                                        <li><span className='tx-500'>Applications</span>
                                            <ul style={{ marginLeft: "35px" }}>
                                                <li>Explore the diverse applications powered by PMIS:</li>
                                            </ul>
                                        </li>


                                        <li><span className='tx-500'>Hardware specifications and configurations</span>

                                            <ul style={{ marginLeft: "35px" }}>
                                                Get familiar with the hardware requirements, including:
                                                <li>Pos Devices</li>
                                                <li>Desktop user machines</li>
                                                <li>Servers (Virtual and Physical)</li>

                                            </ul>
                                        </li>
                                    </ul>
                                    {/* </p> */}
                                </div>
                            </div>
                        </div>
                    </Link>
                    {/* </div> */}
                </div>
            </>
        )

    }

    render() {
        return (
            <div className="page">
                <LeftSideBar />
                <div className="main-content side-content pt-0" >
                    <TopBar />
                    <div className="container-fluid">

                        <PageHeader
                            title="Getting Started"
                            previousPageTitle="PMIS Documentation"
                            back="/"
                        />

                        <div className="row">
                            <div className="col-12">
                                {/* <Link to="#"> */}
                                <div className="">
                                    <div className=" ml-1 my-3">
                                        <div className="float-left mb-4 ">
                                            {/* <h2 className="mb-3 ">Understanding the back-bone of PMIS</h2> */}
                                            <p className="mb-0 w-15 "> Welcome to the PMIS Documentation, your comprehensive guide to understanding the core components of the Parking Management Information System (PMIS). This documentation will provide you with a detailed, step-by-step tutorial on how to navigate the PMIS API effectively.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* </Link> */}
                            </div>
                        </div>

                        <div className="row">
                            <this.IntroSection />
                        </div>


                        <div className="row">
                            {/* <div className="col-6">

                                <PageHeader
                                    title="Support Column"
                                    previousPageTitle="PMIS Documentation"
                                />

                                <div className="card custom-card">

                                    <div className="card-body">
                                        <section className="card_wrapper__content" ref={el => (this.componentRef = el)}>
                                            <h4 style={{ margin: "25px 0" }}>
                                                <Link to="#get-started" className="header-anchor">#</Link> Join Us
                                            </h4>
                                            <p>
                                                Join the Multiplex community by downloading the Plex application on google play store so that you can manage your own parking from there. You can also find enough guidance on how to use the application.
                                                on Discord and connect with the community to exchange ideas, find new opportunities and help build a better web.
                                            </p>
                                            <h4 style={{ margin: "25px 0" }}>
                                                <Link to="#get-started" className="header-anchor">#</Link> Get Help
                                            </h4>
                                            <p>
                                                Incase you need more assistatnce to understand the documentation offered above, you can contact the <Link to="#" target="_blank" rel="noopener noreferrer">administation PMIS</Link>, or <Link to="#" target="_blank" rel="noopener noreferrer">Thrivetec technical team</Link>. They will definetly take you through each detail you would like to know.
                                            </p>
                                        </section>
                                    </div>
                                </div>

                            </div> */}

                            {/* <div className="col-6">

                                <PageHeader
                                    title="Platform Column"
                                    previousPageTitle="PMIS Documentation"
                                />

                                <div className="card custom-card">

                                    <div className="card-body">
                                        <section className="card_wrapper__content" ref={el => (this.componentRef = el)}>

                                                <h4 style={{ margin: "25px 0" }}>
                                                    <Link to="#discover-netlify" className="header-anchor">#</Link> Stacks Employed
                                                </h4>
                                                <p>
                                                    The PMIS is emplotuin a number of frameworks for its smooth flow and implementation. In this documenataion we are going to go int detail with the stacks used in the API creation or what is known as the bakend of the System.
                                                </p>
                                                <p>
                                                    <strong>PHP [Vanila]</strong> – PHP is a <Link to="#">server-side</Link> scripting language embedded in HTML in its simplest form. PHP allows web developers to create dynamic content and interact with databases. PHP is known for its simplicity, speed, and flexibility — features that have made it a cornerstone in the web development world. To learn more about it, visit <Link to="https://www.php.net/manual/en/intro-whatis.php">PHP site</Link>
                                                </p>
                                                <p>
                                                    <strong>SQL [Database]</strong> – Structured Query Language, is a domain-specific language used in programming and designed for managing data held in a relational database management system, or for stream processing in a relational data stream management system. SQL is used to communicate with a database.
                                                </p>

                                        </section>


                                    </div>
                                </div>

                            </div> */}

                            {/* <ProjectMenu /> */}
                            {/* <Code /> */}
                        </div>
                    </div>
                </div>
                {/* <RightSideBar /> */}

            </div>
        );
    }
}

export default WelcomeDocument;