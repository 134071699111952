import React, { useState } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

export const RenderEditIcon = ({ onClickEdit, extraClasses, onClickDelete, hideDelete, tipText, dropdownClass
}) => {

    const [toggle, setToggle] = useState(false);

    const dropdown = () => {
        setToggle(!toggle)
    }
    const onClickEditIcon = () => {
        setToggle(false)
        onClickEdit()
    }

    const onClickDeleteIcon = () => {
        setToggle(false)
        onClickDelete()
    }

    return (
        <span>

            <Tippy content={tipText}>
                <span onClick={dropdown} className={` ${extraClasses} rounded add-icon-cursor d-flex align-items-center justify-content-center w-5 dropdown-toggle`}>
                    <i className="fs-15 p-2 text-primary fas fa-ellipsis-h"></i>
                </span>
            </Tippy>


            <div className={`dropdown-menu ${toggle === true ? `d-block dropdown-active-menu` : ``} tx-13 mt-3`}>
                {/* <span className=''>Edit subtitle here:</span> */}
                <Tippy content={`Click to close`}>
                    <i onClick={() => setToggle(false)} className={`far fa-window-close text-danger pointer float-right pr-3 ${dropdownClass ? dropdownClass : "pt-1"} pb-3`}></i>
                </Tippy>
                <hr style={{ margin: "27px 15px 0 15px" }} />
                <span className="dropdown-item drop-down-text" onClick={onClickEditIcon}>
                    <i className="fas fa-edit mr-2 fs-10"></i> Edit title
                </span>
                {!hideDelete &&<Tippy content="Click to delete page">
                    <span className="dropdown-item drop-down-text align-items-center" onClick={onClickDeleteIcon}>
                        <i className="fas fa-trash-alt mr-2 fs-10 "></i> Delete page
                    </span>
                </Tippy>}

            </div>

        </span>
    );

}


