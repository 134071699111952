import React from 'react'
import { Link } from 'react-router-dom'
import functions from '../../utils/functions';
import $ from 'jquery';
import WarnSuccess from './WarnSuccess';

import { AuthConsumer } from '../../Context/AuthContext';
import { RenderSecure } from '../../utils/script/RenderSecure';

import TextInput from './TextInput';
import ajaxPmisDocs from '../../utils/remote/ajaxPmisDocs';
import Toast from '../../Components/Common/Toast'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useParams } from 'react-router-dom';
import { RenderEditIcon } from './Icons/EditIcon';
import WarnDelete from '../PopUps/WarnDelete';
import DocumentationContext from '../../Context/DocumentationContext';
import { RenderAddIcon } from './Icons/AddIcons';

import dot from '../../assets/images/dot.svg';
import PopAddTitle from '../PopUps/PopAddTitle';
import ActivityLoader from './ActivityLoader';
import NoContent from './NoContent';


class LeftSideBar extends React.Component {
    static contextType = DocumentationContext;

    state = {

        sideBarInitiated: true,
        newSessionDialogue: false,
        newSessionConfirmation: false,
        activeLocationPath: window.location.pathname,
        activeRoot: '',
        add: false,
        title: "",
        docList: false,
        docID: "",
        subtitle: "",
        secondRoot: "",
        message: "",
        info: "",
        showTitleForm: "",
        edit: "",
        loading: "",
        error: "",
        subTitleRoute: "",
        editSubtitle: "",
        subtitleID: "",
        delete: "",
        mdlDelete: false,
        showForm: false,
        modal: false,
        toast: false,
    }

    componentDidMount() {
        const pathSections = this.state.activeLocationPath.split("/");
        this.setState({
            activeRoot: "/" + pathSections[1],
            // secondRoot: "/" + pathSections[2]
        })
    }

    componentDidUpdate(prevProps, prevState) {

        // console.log("prevProps")
        // console.log(prevProps)
        // console.log("prevState")
        // console.log(prevState)

        const pathSections = this.state.activeLocationPath.split("/");
        if (pathSections.length > 2) {
            if (this.state.secondRoot !== pathSections[2]) {
                this.setState({
                    activeRoot: prevProps.activeRoot + "" + prevState.secondRoot,
                    secondRoot: pathSections[2]

                })
            }
        }


    }

    toggleMenu = (e) => {
        e.preventDefault();
        if (e.target.parentElement.nodeName === "A") {

            functions.removeClassFromElementSiblings(e.target.parentElement.offsetParent, 'show')
            e.target.parentElement.offsetParent.classList.toggle('show');
        } else {
            functions.removeClassFromElementSiblings(e.target.parentElement, 'show')
            e.target.parentElement.classList.toggle('show');
        }

    }

    toggleSideBar = () => {
        let element = document.getElementsByTagName('body');
        if (window.matchMedia('(min-width: 768px)').matches) {
            element[0].classList.toggle('main-sidebar-hide');
        } else {
            element[0].classList.toggle('main-sidebar-show');
        }
    }

    openSideBar = () => {

        const controlElement = document.getElementsByClassName('main-sidebar-hide');
        if (controlElement.length > 0) {

            let element = document.getElementsByTagName('body');
            element[0].classList.add('main-sidebar-open')
            this.setState({
                sideBarInitiated: true
            })
        }

    }

    closeSideBar = () => {

        const controlElement = document.getElementsByClassName('main-sidebar-hide');
        if (controlElement.length > 0) {
            let element = document.getElementsByTagName('body');
            element[0].classList.remove('main-sidebar-open')
            this.setState({
                sideBarInitiated: false
            })

        }

    }

    logos = () => {
        return (
            <Link replace className="main-logo" to="#">
                <img src={process.env.PUBLIC_URL + "/images/logo-normal-docs2.svg"} className="header-brand-img desktop-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + "/images/parking_p.png"} className="header-brand-img icon-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + "/images/logo-white.svg"} className="header-brand-img desktop-logo theme-logo" alt="logo" />
                <img src={process.env.PUBLIC_URL + "/images/parking_f.png"} className="header-brand-img icon-logo theme-logo" alt="logo" />
            </Link>
        )
    }

    resetToast = () => {
        this.setState({
            toast: false
        })
    }

    setDocID = (item) => {
        this.setState({
            activeRoot: `/${item.title}`,
            docID: item.doc_id
        })

    }

    setDefaultActiveRoot = () => {
        this.setState({
            activeRoot: `/`,
            docID: ""
        })

    }

    setSubDocID = (item, subtitle) => {
        this.setState({
            activeRoot: `/${item.title}/${subtitle}`,
            subTitleRoute: `/${item.title}/${subtitle}`
        })
    }

    RenderForm = () => {
        return (
            <div className='ml-2'>

                <div className='d-flex justify-content-between align-items-center my-2'>
                    <span className=''>Create title here:</span>
                    <Tippy content={`Click to close`}>
                        <i onClick={this.closeForms} className="far fa-window-close text-danger pointer"></i>
                    </Tippy>
                </div>

                <div className='d-flex'>
                    <form method="post" onSubmit={this.onSaveMainDocument}>
                        <TextInput
                            // label="New Role"
                            // margin="ml-455"
                            wrap
                            type="text"
                            onChange={() => this.onChangeTitle}
                            value={this.state.title}
                        />
                    </form>
                    <button className="plus-btn btn ripple btn-primary" type="button" onClick={this.onSaveMainDocument}>
                        {/* <i className="text-white fas fa-plus"></i> */}
                        {/* <i className="text-white fas fa-paper-plane-top"></i> */}
                        {!this.state.loading && <i className="text-white fas fa-paper-plane"></i>}
                        {this.state.loading && <ActivityLoader customClass="m-0 p-0 text-center" color="#fff" height="20px" width="20px" />}
                    </button>
                </div>

                {/* {this.state.message && <span className='text-success mt-n2'>{this.state.message}</span>}
                {this.state.error && <span className='text-danger mt-n2'>{this.state.error}</span>} */}

            </div>

        )
    }

    RenderAddSubTilteForm = () => {
        return (
            <div className='ml-2'>

                <div className='d-flex justify-content-between align-items-center my-2'>
                    <span className=''>Create subtitle here:</span>
                    <Tippy content={`Click to close`}>
                        <i onClick={this.closeForms} className="far fa-window-close text-danger pointer"></i>
                    </Tippy>
                </div>

                <div className='d-flex'>
                    <form method="post" onSubmit={this.onSaveSubtitle}>
                        <TextInput
                            // margin
                            // marginTop
                            wrap
                            type="text"
                            onChange={() => this.onChangeSubTitle}
                            value={this.state.subtitle}
                        />
                    </form>
                    <button className="plus-btn btn ripple btn-primary" type="button" onClick={this.onSaveSubtitle}>
                        {/* <i className="text-white fas fa-plus"></i> */}
                        {/* <i className="text-white fas fa-paper-plane-top"></i> */}
                        {!this.state.loading && <i className="text-white fas fa-paper-plane"></i>}
                        {this.state.loading && <ActivityLoader customClass="m-0 p-0 text-center" color="#fff" height="20px" width="20px" />}
                    </button>

                </div>
                {/* {this.state.message && <span className='text-success mt-n5'>{this.state.message}</span>}
                {this.state.error && <span className='text-danger mt-n5'>{this.state.error}</span>} */}
            </div>

        )
    }

    closeForms = () => {
        this.setState({
            edit: false,
            add: false,
            editSubtitle: false,
            showTitleForm: false

        })
    }

    RenderEditForm = () => {
        return (
            <div>
                <div className='d-flex justify-content-between align-items-center mb-2'>

                    <span className=''>Edit your title here:</span>
                    <Tippy content={`Click to close`}>
                        <i onClick={this.closeForms} className="far fa-window-close text-danger pointer "></i>
                    </Tippy>
                </div>

                <div className='d-flex'>
                    <form method="post" onSubmit={this.onEditMainTitle}>
                        <TextInput
                            // label="New Role"
                            // margin="ml-455"
                            wrap
                            type="text"
                            onChange={() => this.onChangeTitle}
                            value={this.state.title}
                        />
                    </form>
                    <button className="plus-btn btn ripple btn-primary" type="button" onClick={this.onEditMainTitle}>
                        {/* <i className="text-white fas fa-plus"></i> */}
                        {/* <i className="text-white fas fa-paper-plane-top"></i> */}
                        <i className="text-white fas fa-paper-plane"></i>
                    </button>
                </div>

                {this.state.message && <span className='text-success mt-n5'>{this.state.message}</span>}
                {this.state.error && <span className='text-danger mt-n5'>{this.state.error}</span>}

            </div>

        )
    }

    RenderSubtitleEditForm = () => {
        return (
            <div className='p-2'>
                <div className='d-flex justify-content-between align-items-center mb-2 mt-2 '>

                    <span className=''>Edit subtitle here:</span>
                    <Tippy content={`Click to close`}>
                        <i onClick={this.closeForms} className="far fa-window-close text-danger pointer "></i>
                    </Tippy>
                </div>
                <div className='d-flex'>
                    <form method="post" onSubmit={this.onEditSubTitle}>
                        <TextInput
                            // label="New Role"
                            // margin="ml-455"
                            wrap
                            type="text"
                            onChange={() => this.onChangeSubTitle}
                            value={this.state.subtitle}
                        />
                    </form>
                    <button className="plus-btn btn ripple btn-primary" type="button" onClick={this.onEditSubTitle}>
                        {/* <i className="text-white fas fa-plus"></i> */}
                        {/* <i className="text-white fas fa-paper-plane-top"></i> */}
                        <i className="text-white fas fa-paper-plane"></i>
                    </button>
                </div>

                {this.state.message && <span className='text-success mt-n2'>{this.state.message}</span>}
                {this.state.error && <span className='text-danger mt-n2'>{this.state.error}</span>}

            </div>

        )
    }

    onSaveMainTitle = async (titleName) => {
        const { listAllTitles } = this.context;
        // const { title } = this.state

        this.setState({
            info: false,
            mainTitle: false,
            loading: true
        })
        if (titleName.length > 0) {
            const server_response = await ajaxPmisDocs.createMainDoc(titleName, '');
            functions.closePopup("modal_add_title");
            if (server_response.status === "OK") {
                this.setState({
                    mainTitle: titleName,
                    toast: <Toast message={server_response.details.message} onClose={this.resetToast} />,
                    info: <Toast message={server_response.details.message} />,
                    message: server_response.details.message,
                    loading: false,
                })

                setTimeout(() => {
                    this.setState({
                        showTitleForm: "",
                        message: "",
                        error: "",
                        loading: false,
                        toast: false
                    }, () => listAllTitles())
                }, 3000);
            } else {
                this.setState({
                    toast: <Toast message={server_response.details.message} type={2} onClose={this.resetToast} />,
                    error: server_response.details.message,
                    loading: false
                })
            }

        }

    }

    onSaveMainDocument = async () => {
        const { title } = this.state
        this.setState({
            info: false,
            mainTitle: false,
            loading: true
        })

        const server_response = await ajaxPmisDocs.createMainDoc(title, '')

        if (server_response.status === "OK") {
            const { listAllTitles } = this.context;

            this.setState({
                mainTitle: title,
                info: <Toast message={server_response?.details?.message} />,
                message: server_response.details.message,
                loading: false,
                toast: <Toast message={server_response.details.message} onClose={this.resetToast} />,
            })

            setTimeout(() => {
                this.setState({
                    showTitleForm: "",
                    message: "",
                    error: "",
                    loading: false,
                    toast: false
                }, () => listAllTitles())
            }, 3000);
        } else {
            this.setState({
                info: <Toast message={server_response?.details?.message} type={2} />,
                error: server_response.details.message,
                loading: false,
                toast: <Toast message={server_response?.details?.message} type={2} onClose={this.resetToast} />,
            })
        }
    }


    onSaveSubtitle = async () => {

        const { docID, subtitle, mainTitle } = this.state
        this.setState({
            info: false,
            subtitle: false
        })
        const server_response = await ajaxPmisDocs.createSubDoc(docID, subtitle, '')
        console.log(server_response)
        if (server_response.status == "OK") {
            const { listAllTitles } = this.context;


            this.setState({
                subtitle: "",
                info: <Toast message={server_response.details.message} />,
                message: server_response.details.message
            })

            setTimeout(() => {
                this.setState({
                    add: "",
                    message: "",
                    error: ""
                }, () => listAllTitles())
            }, 3000);

        } else {
            this.setState({
                info: <Toast message={server_response.details.message} type={2} />,
                error: server_response.details.message

            })
        }

    }

    onClickAddTitle = () => {

        this.setState({
            modal: false,
        }, () => {
            this.setState({
                modal: <PopAddTitle onConfirm={this.onSaveMainTitle} />
            }, () => functions.openPopup("modal_add_title")
            )
        })

    }

    onEditMainTitle = async () => {
        const { title, docID } = this.state
        this.setState({
            info: false,
            mainTitle: false
        })

        const server_response = await ajaxPmisDocs.updateMainDocTitle(docID, title)
        if (server_response.status == "OK") {
            const { listAllTitles } = this.context;

            this.setState({
                mainTitle: title,
                title: "",
                info: <Toast message={server_response.details.message} />,
                message: server_response.details.message
            })

            setTimeout(() => {
                this.setState({
                    edit: "",
                    message: ""
                }, () => listAllTitles())
            }, 3000);
        } else {
            this.setState({
                info: <Toast message={server_response.details.message} type={2} />
            })
        }
    }

    onEditSubTitle = async () => {
        const { subtitle, subtitleID } = this.state
        this.setState({
            info: false,
            subtitle: false
        })

        const server_response = await ajaxPmisDocs.updateSubDocTitle(subtitleID, subtitle)
        if (server_response.status == "OK") {
            const { listAllTitles } = this.context;

            this.setState({
                subtitle: "",
                info: <Toast message={server_response.details.message} />,
                message: server_response.details.message
            })

            setTimeout(() => {
                this.setState({
                    editSubtitle: "",
                    message: ""
                }, () => listAllTitles())
            }, 3000);
        } else {
            this.setState({
                info: <Toast message={server_response.details.message} type={2} />
            })
        }
    }

    onConfirmDelete = async () => {
        const { subtitle, subtitleID } = this.state
        this.setState({
            info: false,
            subtitle: false
        })

        const server_response = await ajaxPmisDocs.deleteSubTitle(subtitleID)
        if (server_response.status == "OK") {
            const { listAllTitles } = this.context;

            this.setState({
                subtitle: "",
                info: <Toast message={server_response.details.message} />,
                message: server_response.details.message
            })

            setTimeout(() => {
                this.setState({
                    message: ""
                }, () => listAllTitles())
            }, 3000);
        } else {
            this.setState({
                info: <Toast message={server_response.details.message} type={2} />
            })
        }
    }

    onChangeTitle = (event) => {
        this.setState({
            title: event.target.value
        })
    }

    onChangeSubTitle = (event) => {
        this.setState({
            subtitle: event.target.value
        })
    }

    onConfirm = () => {
        const { title } = this.state
        this.props.onSave(title)
    }

    onClickAdd = () => {
        this.setState({
            add: !this.state.add,
            showTitleForm: false,

        })
    }

    onClickEdit = (title) => {
        this.setState({
            edit: !this.state.edit,
            title: title,
        })
    }

    // Delete subtitle
    onClickDelete = () => {
        this.setState({
            delete: !this.state.delete
        })
    }

    onClickEditSubtitle = (subtitleItem) => {
        this.setState({
            editSubtitle: !this.state.editSubtitle,
            subtitle: subtitleItem.subtitle,
            subtitleID: subtitleItem.subtitle_id
        })
    }

    onClickOptionIcon = () => {
        this.setState({
            edit: "",
            delete: "",
            showTitleForm: false
        })
    }

    onClickAddMainTitle = () => {
        this.setState({ showTitleForm: !this.state.showTitleForm })
    }

    onClickDeleteSubTitle = () => {
        this.props.onClickDeleteIcon()
    }

    onClickDeleteMainTitle = () => {
        this.props.onClickDeleteTitle()
    }

    toggleDropdown = (idSelector) => {
        //functions.removeClassFromElements('header-dropdown', 'show') 
        setTimeout(() => {
            document.getElementById(idSelector).classList.toggle('show');

        }, 100);
    }

    render() {
        // const {activeRoot} = this.state
        const { docList } = this.context;
        // console.log(window.location.href.split('/'))
        // console.log("window.location.href")
        return (
            <>
                {this.state.modal}
                {this.state.toast}

                <AuthConsumer>
                    {props => {
                        const permissionList = props.permissionList;
                        const activeRoot = this.state.activeRoot;
                        const path = this.state.activeLocationPath;
                        const USER_BOUNDARY = functions.userBoundary();
                        const roleList = functions.roleGuard()
                        return (
                            <>
                                <div className="main-sidebar main-sidebar-sticky side-menu"
                                    onMouseEnter={this.openSideBar}
                                    onMouseLeave={this.closeSideBar}>
                                    {/* {this.state.mdlDelete} */}
                                    <div className="sidemenu-logo mt-2">
                                        <div className="touch-close-menu">
                                            <i className="fas fa-align-left custom-menu-icon" onClick={this.toggleSideBar}></i>
                                        </div>
                                        <this.logos />
                                    </div>

                                    <div className="main-sidebar-body">
                                        <ul className='nav'>
                                            <li className="nav-label d-flex align-items-center justify-content-between">
                                                Documentation
                                                <span className="sidemenu-label float-right">
                                                    <Tippy content="Click to create title">
                                                        <span
                                                            onClick={this.onClickAddMainTitle}
                                                            // onClick={this.onClickAddTitle}
                                                            className='rounded add-icon-cursor d-flex align-items-center justify-content-center w-5'
                                                        >
                                                            <i className="fs-16 text-primary fas fa-plus"></i>
                                                        </span>
                                                    </Tippy>
                                                </span>
                                            </li>

                                            {/* rrenders form for creating a document title */}
                                            {/* {this.state.loading && <span>loading...</span>} */}
                                            {this.state.showTitleForm && <this.RenderForm />}

                                            <li className={`nav-item ${activeRoot === '/' && 'active show'}`} >
                                                <Link className="nav-link with-sub" to="/">
                                                    <span style={{ marginLeft: "-10px" }}><img src={dot} alt="" /></span>
                                                    <span className="sidemenu-label">Getting started</span>
                                                </Link>
                                            </li>

                                            <li className={`nav-item ${activeRoot === '/installation-guide' && 'active show'}`} >
                                                <Link className="nav-link with-sub" to="/installation-guide">
                                                    <span style={{ marginLeft: "-10px" }}><img src={dot} alt="" /></span>
                                                    <span className="sidemenu-label">Installation Guide</span>
                                                </Link>
                                            </li>

                                            {docList && Array.isArray(docList) &&
                                                docList.map((item, key) => (
                                                    <li
                                                        key={key}
                                                        onClick={() => this.setDocID(item)}
                                                        className={`nav-item  ${(activeRoot === `/${item.title}`) && 'active show'}`}
                                                    >
                                                        {!item.has_subtitle &&
                                                            <>
                                                                <Link className="nav-link"
                                                                    // onClick={
                                                                    //     this.toggleMenu
                                                                    // }
                                                                    to={{
                                                                        pathname: `/${item.title}`,
                                                                        state: {
                                                                            mainTitleDescription: item.title_description,
                                                                            titleID: item.doc_id,
                                                                            mainTitle: item.title,
                                                                            has_subtitle: item.has_subtitle,
                                                                            mainDocRef: item.doc_ref,
                                                                            pageHeadings: item.page_headings
                                                                        }
                                                                    }}>

                                                                    <span style={{ marginLeft: "-10px" }}><img src={dot} alt="" /></span>
                                                                    <span className='w-100 d-flex align-items-center justify-content-between'>
                                                                        {/* {item.title} */}

                                                                        {item.title.length > 17 ? item.title.substring(0, 17) + '...' : item.title}

                                                                        {activeRoot === `/${item.title}` &&
                                                                            <div className='d-flex align-items-center justify-content-between m-0 p-0'>
                                                                                {!this.state.add && !this.state.edit && <RenderEditIcon
                                                                                    onClickEdit={() => this.onClickEdit(item.title)}
                                                                                    onClickOptionIcon={() => this.onClickOptionIcon()}
                                                                                    onClickDelete={this.onClickDeleteMainTitle}
                                                                                    tipText={`Click for more options`}
                                                                                    dropdownClass="pt-2"

                                                                                />}

                                                                                {!this.state.add && !this.state.edit && <RenderAddIcon
                                                                                    onClickAdd={this.onClickAdd}
                                                                                    tipText={"Click to add subtitle"}
                                                                                    extraClasses="add-title-icon-cursor"
                                                                                />}
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </Link>

                                                                {this.state.docID === item.doc_id &&
                                                                    <span className='nav-item '>
                                                                        {this.state.add && <this.RenderAddSubTilteForm />}

                                                                        {this.state.edit && <this.RenderEditForm title={item.title} />}
                                                                    </span>
                                                                }
                                                            </>
                                                        }

                                                        {item.has_subtitle &&
                                                            <>

                                                                {/* shows main title */}
                                                                <span onClick={this.toggleMenu}>
                                                                    <Link className="nav-link" to={{
                                                                        pathname: `/${item.title}`,
                                                                        state: {
                                                                            mainTitleDescription: item.title_description,
                                                                            titleID: item.doc_id,
                                                                            mainTitle: item.title,
                                                                            has_subtitle: item.has_subtitle,
                                                                            mainDocRef: item.doc_ref,
                                                                            pageHeadings: item.page_headings,
                                                                            isMain: true
                                                                        }
                                                                    }}>
                                                                        <span style={{ marginLeft: "-10px" }}><img src={dot} alt="" /></span>
                                                                        <span className="sidemenu-label"> {item.title}</span>
                                                                        <i className="angle fe fe-chevron-right"></i>
                                                                    </Link>
                                                                </span>

                                                                <ul className="nav-sub">
                                                                    <li className={`'nav-sub-item bg-lighter   ${this.state.edit ? 'mb-3' : ''}`}>
                                                                        <span className='d-flex justify-content-between align-items-center fs-12  p-0 m-0 border'>
                                                                            <span className="sidemenu-label pl-2 font-weight-bold tx-gray-700  float-left  ">Add subtitle</span>

                                                                            {!this.state.add && !this.state.edit && !this.state.editSubtitle &&
                                                                                <RenderEditIcon
                                                                                    onClickEdit={() => this.onClickEdit(item.title)}
                                                                                    extraClasses="ml-4 float-right sidemenu-label edit-cursor "
                                                                                    onClickOptionIcon={this.onClickOptionIcon}
                                                                                    tipText={"Click for more options"}
                                                                                    // onClickDelete={this.onClickDeleteMainTitle}
                                                                                    hideDelete
                                                                                />}

                                                                            {activeRoot === `/${item.title}` &&
                                                                                <RenderAddIcon
                                                                                    onClickAdd={this.onClickAdd}
                                                                                    tipText={"Click to add subtitle"}
                                                                                    extraClasses="add-icon-cursor"
                                                                                />

                                                                            }
                                                                        </span>
                                                                    </li>

                                                                    {/* render subtitle forms */}
                                                                    {this.state.docID === item.doc_id &&
                                                                        <>
                                                                            {this.state.add && <this.RenderAddSubTilteForm />}
                                                                            {this.state.edit &&
                                                                                <span className='mt-3'>
                                                                                    <this.RenderEditForm
                                                                                        title={item.title}
                                                                                    />
                                                                                </span>}
                                                                        </>
                                                                    }
                                                                    {/* render subtitles */}
                                                                    {Array.isArray(item.subtitles) &&
                                                                        item.subtitles.map((subtitleItem, subtitleKey) => (
                                                                            <li key={subtitleKey} className={`nav-sub-item ${activeRoot === '/' + item.title + "/" + this.state.secondRoot && 'active'}`}>
                                                                                <Link
                                                                                    className="nav-sub-link"
                                                                                    onClick={() => this.setSubDocID(item, subtitleItem.subtitle)}
                                                                                    to={{
                                                                                        pathname: '/' + item.title + '/' + subtitleItem.subtitle,
                                                                                        state: {
                                                                                            titleID: item.doc_id,
                                                                                            subtitle: subtitleItem.subtitle,
                                                                                            subtitle_description: subtitleItem.subtitle_description,
                                                                                            mainTitle: item.title,
                                                                                            subtitleID: subtitleItem.subtitle_id,
                                                                                            has_subtitle: item.has_subtitle,
                                                                                            subtitleDocRef: subtitleItem.doc_ref,


                                                                                        }
                                                                                    }}>
                                                                                    <span className="sidemenu-label">{subtitleItem.subtitle}</span>
                                                                                    {(this.state.subTitleRoute === `/${item.title}/${subtitleItem.subtitle}`) && !this.state.add && !this.state.edit && !this.state.editSubtitle &&
                                                                                        <RenderEditIcon
                                                                                            tipText="Click for more option"
                                                                                            onClickEdit={() => this.onClickEditSubtitle(subtitleItem)}
                                                                                            deleteIcon
                                                                                            onClickDelete={this.onClickDeleteSubTitle}
                                                                                            onClickOptionIcon={this.onClickOptionIcon}
                                                                                            extraClasses="ml-4 "
                                                                                        />
                                                                                    }
                                                                                </Link>
                                                                                {this.state.editSubtitle && this.state.subtitleID === subtitleItem.subtitle_id &&
                                                                                    <span className='m-6'>
                                                                                        <this.RenderSubtitleEditForm
                                                                                            subtitle={subtitleItem.subtitle}
                                                                                        />
                                                                                    </span>
                                                                                }
                                                                            </li>))}
                                                                </ul>
                                                            </>
                                                        }
                                                    </li>
                                                ))
                                            }

                                        </ul>
                                        {!docList && <ActivityLoader
                                            type={10}
                                            height={40}
                                            width={35}
                                        />}

                                        {docList == "fail" && <NoContent subTitle="Contacting Server!" width="30" />}

                                        {docList == "404" && <NoContent subTitle="No Content"/>}
                                        
                                      
                                    </div>
                                  
                                </div >
                               
                            </>
                            
                        )
                    }
                    }
                </AuthConsumer >

            </>
        )

    }

}

export default LeftSideBar
