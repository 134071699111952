import Toast from '../../Components/Common/Toast';
import React, { Component } from 'react';
import LeftSideBar from '../../Components/Common/LeftSideBar';
import PageHeader from '../../Components/Common/PageHeader';
import TopBar from '../../Components/Common/TopBar';
import { TextEditor } from '../../Components/TextEditor/TextEditor';
import ajaxPmisDocs from '../../utils/remote/ajaxPmisDocs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Markup } from 'interweave';

import ProjectMenu from './ProjectMenu';
import EditorContext from '../../Context/EditorContext';
import WarnDelete from '../../Components/PopUps/WarnDelete';
import functions from '../../utils/functions';
import DocumentationContext from '../../Context/DocumentationContext';
import { createRef } from 'react';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import PopEditDescription from '../../Components/PopUps/EditDescriptionPo';
// import PopEditDescription from '../../Components/PopUps/EditDescription';
import NoContent from '../../Components/Common/NoContent';
import PopAddDescription from '../../Components/PopUps/PopAddDescription';

class DocManager extends Component {

    constructor(props) {
        super(props)
        this.componentRef = createRef();
    }

    static contextType = DocumentationContext;

    state = {
        title: "",
        info: "",
        mainTitle: "",
        description: "",
        subtitleDescription: "",
        subtitle: "",
        titleID: "",
        subtitleID: "",
        mdlDelete: false,
        countActiveLocationPath: window.location.pathname.split("/").length,
        projectMenu: true,
        pageHeadings: ""
    }

    componentDidMount() {

        if (!this.props.location?.state) {

            this.setState({
                description: "",
            })
        } else {
            const { setSubTitleID, setTitleID } = this.context
            if (this.props.location.state.subtitleID) {
                setSubTitleID(this.props.location.state.subtitleID)
            }
            if (this.props.location.state.titleID) {
                setTitleID(this.props.location.state.titleID)
            }

            // if (this.props.location.state.mainTitleDescription) {
            //     this.setState
            // }

            this.setState({ mainTitleDescription: "", subtitleDescription: "" }, () =>
                this.setState({
                    description: this.props.location.state?.description,
                    subtitleDescription: this.props.location?.state?.subtitle_description,
                    // title: this.props.location.state.title,
                    subtitle: this.props.location.state.subtitle,
                    titleID: this.props.location?.state?.titleID,
                    mainTitle: this.props.location.state.mainTitle,
                    subtitleID: this.props.location.state.subtitleID,
                    subtitle: this.props.location.state.subtitle,
                    mainTitleDescription: this.props.location.state.mainTitleDescription,
                    has_subtitle: this.props.location?.state?.has_subtitle,
                    pageHeadings: this.props.location?.state?.pageHeadings
                }))
        }
    }

    //--- creates doc heading ---
    saveDocument = async (title) => {
        this.setState({
            info: false,
            mainTitle: false
        })

        const server_response = await ajaxPmisDocs.createMainDoc(title, '')
        if (server_response.status == "OK") {
            this.setState({
                mainTitle: title,
                info: <Toast message={server_response.details.message} />
            })
        } else {
            this.setState({
                info: <Toast message={server_response.details.message} type={2} />
            })
        }
    }

    onSaveMainDocHeading = async (heading) => {
        const titleID = this.props.location?.state?.titleID
        const mainTitle = this?.props?.location?.state?.mainTitle
        const pageHeadings = this.props.location?.state?.pageHeadings
        const mainDocRef = this.props.location?.state?.mainDocRef
        const subtitleDocRef = this.props.location?.state?.subtitleDocRef



        const server_response = await ajaxPmisDocs.createPageSummary(titleID, heading, mainDocRef)
        functions.closePopup("modal_add_page_summary");
        if (server_response.status === "OK") {
            const { listAllTitles } = this.context;
            listAllTitles()
            this.props.history.push({
                pathname: '/' + mainTitle,
                state: {
                    ...this.props.location.state,
                    pageHeadings: pageHeadings
                }
            })
            this.setState({
                info: <Toast message={server_response.details.message} />
            })
        } else {
            this.setState({
                info: <Toast message={server_response.details.message} type={2} />
            })
        }
    }



    //--- creates description ---
    //--- Dosent include creating subtitle description ---

    saveMainDocDescription = async (htmlDescription) => {
        const titleID = this.props.location?.state?.titleID
        const mainTitle = this?.props?.location?.state?.mainTitle
        const pageHeadings = this.props.location?.state?.pageHeadings


        const server_response = await ajaxPmisDocs.createMainDocDescription(titleID, htmlDescription)
        console.log(server_response)
        console.log("Main description method")
        functions.closePopup("modal_add_description");
        if (server_response.status === "OK") {

            this.setState({
                subtitleDescription: htmlDescription
            })
            this.props.history.push({
                pathname: '/' + mainTitle + '/' + this.props.location?.state?.subtitle,
                state: {
                    mainTitleDescription: htmlDescription,
                    titleID: titleID,
                    mainTitle: mainTitle,
                    pageHeadings: pageHeadings
                }
            })
            this.setState({
                info: <Toast message={server_response.details.message} />
            })
        } else {
            this.setState({
                info: <Toast message={server_response.details.message} type={2} />
            })
        }
    }

    addSubTitle = async (subtitle, htmlDescription) => {

        const subtitleID = this.props.location?.state?.subtitleID

        // const subtitle = this.props.location?.state?.subtitle
        const subtitleDescription = this.props.location?.state?.subtitle_description ? this.props.location?.state?.subtitle_description : this.state.subtitleDescription
        const mainTitle = this?.props?.location?.state?.mainTitle
        this.setState({
            info: false,
            // subtitleDescription: ""
        })
        const server_response = await ajaxPmisDocs.createSubtitleDescription(subtitleID, htmlDescription)
        console.log(server_response)
        console.log("Does it use this method")

        if (server_response.status == "OK") {
            const { listAllTitles } = this.context

            functions.closePopup("modal_add_description");

            this.setState({
                subtitleDescription: htmlDescription
            })
            this.props.history.push({
                pathname: '/' + mainTitle + '/' + this.props.location?.state?.subtitle,
                state: {
                    // titleID: item.doc_id,
                    subtitle: this.props.location?.state?.subtitle,
                    subtitle_description: htmlDescription,
                    mainTitle: mainTitle,
                    subtitleID: subtitleID,
                    has_subtitle: this.props.location?.state?.has_subtitle


                }
            })

            this.setState({
                modal: false,
                info: <Toast message={server_response.details.message} />,
            }, () => listAllTitles())
        } else {
            this.setState({
                info: <Toast message={server_response.details.message} type={2} />
            })
        }
    }

    onConfirmDelete = async () => {
        const subtitleID = this.props.location?.state?.subtitleID
        const titleID = this.props.location?.state?.titleID
        const mainTitle = this.props.location?.state?.mainTitle
        const subtitle = this.props.location?.state?.subtitle
        const subtitleDescription = this.props.location?.state?.subtitle_description
        this.setState({
            info: false,
            subtitle: false
        })

        const server_response = await ajaxPmisDocs.deleteSubTitle(subtitleID, titleID)
        functions.closePopup("modal_delete")
        if (server_response.status == "OK") {
            const { listAllTitles, setTitleID } = this.context
            setTitleID(titleID)
            this.setState({
                subtitle: "",
                info: <Toast message={server_response.details.message} />,
                message: server_response.details.message
            })
            const { singleDocInfo } = this.context
            setTimeout(() => {
                this.setState({
                    message: ""
                }, () => listAllTitles())
                this.props.history.push({
                    pathname: '/' + mainTitle,
                    state: {
                        // titleID: item.doc_id,
                        subtitle: singleDocInfo.title_descriotion,
                        subtitleDescription: singleDocInfo.title_descriotion,
                        mainTitle: mainTitle,
                        subtitleID: subtitleID
                    }
                })
            }, 3000);
        } else {
            this.setState({
                info: <Toast message={server_response.details.message} type={2} />
            })
        }
    }

    onConfirmDeleteMainDoc = async () => {
        const titleID = this.props.location?.state?.titleID
        const mainTitle = this.props.location?.state?.mainTitle
        const subtitleID = this.props.location?.state?.subtitleID

        this.setState({
            info: false,
            subtitle: false
        })

        const server_response = await ajaxPmisDocs.deleteMainTitle(titleID)
        functions.closePopup("modal_delete")
        if (server_response.status == "OK") {
            const { listAllTitles, setTitleID } = this.context
            setTitleID(titleID)
            this.setState({
                subtitle: "",
                info: <Toast message={server_response.details.message} />,
                message: server_response.details.message
            })
            const { singleDocInfo } = this.context
            setTimeout(() => {
                this.setState({
                    message: ""
                }, () => listAllTitles())
                this.props.history.push({
                    pathname: '/',
                    state: {
                        titleID: singleDocInfo.title_id,
                        subtitle: singleDocInfo.title_descriotion,
                        subtitleDescription: singleDocInfo.title_descriotion,
                        mainTitle: mainTitle,
                        subtitleID: subtitleID
                    }
                })
            }, 3000);
        } else {
            this.setState({
                info: <Toast message={server_response.details.message} type={2} />
            })
        }
    }


    /* displays a popup when a delete icon is clicked */
    onClickDeleteSubTitle = () => {
        this.setState({
            mdlDelete: false
        }, () => {
            this.setState({
                mdlDelete: <WarnDelete
                    title={`Delete '${this.props.location?.state?.subtitle}' document`}
                    description={"You are about to delete this document." + "  Please note that all information about this document will be lost!"}
                    onConfirm={() => this.onConfirmDelete()} />
            }, () => { functions.openPopup("modal_delete") })
        })
    }

    onClickDeleteMainTitle = () => {
        this.setState({
            mdlDelete: false
        }, () => {
            this.setState({
                mdlDelete: <WarnDelete
                    title={`Delete '${this.props.location?.state?.mainTitle}' document`}
                    description={"You are about to delete this document." + "  Please note that all information about this document will be lost!"}
                    onConfirm={() => this.onConfirmDeleteMainDoc()} />
            }, () => { functions.openPopup("modal_delete") })
        })
    }

    buttons = () => {

        const countActiveLocationPath = window.location.pathname.split("/").length


        return (
            <>

                <button className="btn ripple btn-light" onClick={countActiveLocationPath == 2 ? this.onClickEditDocument : this.onClickEditSubtitleDocument}>
                    <i className="far fa-edit "></i> Edit
                </button>

                <ReactToPrint
                    trigger={() =>
                        <button className="btn ripple btn-light">
                            <i className="fa-solid fa-arrow-up-right-from-square "></i> Print
                        </button>}
                    content={() => this.componentRef}
                />


            </>

        )
    }

    onClickEditDocument = () => {

        //  const { singleDocInfo, subtitleInfo } = this.context
        // const mainTitleDescription = this.props?.location?.state?.mainTitleDescription ?? singleDocInfo.title_description
        // const subtitleDescription = this.props.location?.state?.subtitle_description ?? subtitleInfo?.subtitle_description
        const description = this.props.location?.state?.mainTitleDescription
        this.setState({
            modal: false
        }, () => {
            this.setState({
                modal: <PopEditDescription
                    description={description}
                    editDescription={this.onconfirmEditMainDescription}

                />
            }, () => functions.openPopup("modal_edit_description"))
        })
    }


    onClickEditSubtitleDocument = () => {

        //  const { singleDocInfo, subtitleInfo } = this.context
        // const mainTitleDescription = this.props?.location?.state?.mainTitleDescription ?? singleDocInfo.title_description
        // const subtitleDescription = this.props.location?.state?.subtitle_description ?? subtitleInfo?.subtitle_description
        const description = this.props.location?.state?.subtitle_description
        this.setState({
            modal: false
        }, () => {
            this.setState({
                modal: <PopEditDescription
                    description={description}
                    editSubtitleDescription={this.onconfirmEditSubtitleDescription}
                    hasSubtitle

                />
            }, () => functions.openPopup("modal_edit_description"))
        })
    }

    onconfirmEditSubtitleDescription = async (htmlDescription) => {
        const mainTitle = this.props?.location?.state?.mainTitle
        const subtitle = this.props?.location?.state?.subtitle
        const subtitleID = this.props?.location?.state?.subtitleID


        const server_response = await ajaxPmisDocs.updateSubtitleDescription(subtitleID, htmlDescription)
        console.log(server_response)
        if (server_response.status == "OK") {
            const { listAllTitles } = this.context;

            this.props.history.push({
                pathname: '/' + mainTitle + '/' + subtitle,
                state: {
                    subtitle_description: htmlDescription,
                    subtitleID: subtitleID,
                    mainTitle: mainTitle,
                    subtitle: subtitle
                }
            })
            functions.closePopup("modal_edit_description")

            this.setState({
                modal: false,
                info: <Toast message={server_response.details.message} />,
            }, () => listAllTitles())

        } else {
            this.setState({
                info: <Toast message={server_response.details.message} type={2} />
            })
        }
    }

    onconfirmEditMainDescription = async (htmlDescription) => {
        const { titleID } = this.state
        const mainTitle = this.props?.location?.state?.mainTitle
        const mainDocRef = this.props.location?.state?.mainDocRef
        const pageHeadings = this.props.location?.state?.pageHeadings
        const server_response = await ajaxPmisDocs.updateMainDescription(titleID, htmlDescription)
        if (server_response.status == "OK") {
            const { listAllTitles } = this.context;

            this.props.history.push({
                pathname: '/' + mainTitle,
                state: {
                    mainTitleDescription: htmlDescription,
                    titleID: titleID,
                    mainTitle: mainTitle,
                    pageHeadings: pageHeadings
                }
            })
            functions.closePopup("modal_edit_description")

            this.setState({
                modal: false,
                info: <Toast message={server_response.details.message} />,
            }, () => listAllTitles())

        } else {
            this.setState({
                info: <Toast message={server_response.details.message} type={2} />
            })
        }
    }

    onClickAddDescription = () => {


        this.setState({
            modal: false,
        }, () => {
            this.setState({
                modal: <PopAddDescription
                    subtitle={this.props.location?.state?.subtitle}
                    subtitleID={this.props.location?.state?.subtitleID}
                    mainTitle={this?.props?.location?.state?.mainTitle}
                    hasSubtitle={this.props.location?.state?.has_subtitle}
                    addDescription={this.saveMainDocDescription}
                    isMain={this?.props?.location?.state?.isMain}
                    addSubtitleDescription={!this?.props?.location?.state?.isMain ? this.addSubTitle : {}}
                />
            }, () => functions.openPopup("modal_add_description")
            )
        })

    }

    // {has_subtitle !== true &&
    // (mainTitleDescription ?

    // {/* (<TextEditor
    //     subtitle={subtitle}
    //     subtitleID={subtitleID}
    //     mainTitle={mainTitle}
    //     noSubDocs
    //     addDescription={this.saveMainDocDescription}
    // />) */}

    // {has_subtitle && countActiveLocationPath > 2 &&
    //     (!subtitleDescription ?

    // {/* (<TextEditor
    //     subtitle={subtitle}
    //     subtitleID={subtitleID}
    //     mainTitle={mainTitle}
    //     hasSubtitle
    //     addSubtitleDescription={this.addSubTitle}
    // />) */}

    // {has_subtitle && countActiveLocationPath === 2 &&
    //     (mainTitleDescription ?

    // {/* (<TextEditor
    //     subtitle={subtitle}
    //     subtitleID={subtitleID}
    //     mainTitle={mainTitle}
    //     addDescription={this.saveMainDocDescription}
    // />) */}





    render() {
        const subtitle = this.props.location?.state?.subtitle
        const mainTitle = this?.props?.location?.state?.mainTitle
        const subtitleID = this.props.location?.state?.subtitleID
        const has_subtitle = this.props.location?.state?.has_subtitle
        const { singleDocInfo, subtitleInfo } = this.context
        const mainTitleDescription = this.props?.location?.state?.mainTitleDescription ?? singleDocInfo.title_description
        const subtitleDescription = this.props.location?.state?.subtitle_description ?? subtitleInfo?.subtitle_description
        const countActiveLocationPath = window.location.pathname.split("/").length
        const maintitleID = this.props.location?.state?.titleID
        const subtitleDocRef = this.props.location?.state?.subtitleDocRef
        const mainDocRef = this.props.location?.state?.mainDocRef
        // const pageHeadings = this.props.location?.state?.pageHeadings
        const { pageHeadings } = this.state



        return (
            <div className="page">
                {this.state.mdlDelete}
                {this.state.modal}
                {this.state.toast}

                <LeftSideBar
                    onSave={this.saveDocument}
                    onClickDeleteIcon={this.onClickDeleteSubTitle}
                    onClickDeleteTitle={() => this.onClickDeleteMainTitle()}
                />

                <div className="main-content side-content pt-0" >
                    <TopBar />
                    {this.state.info}

                    <div className="container-fluid">

                        <PageHeader
                            title={subtitle ? subtitle : mainTitle}
                            previousPageTitle={subtitle ? mainTitle : "PMIS Documentation"}
                            back={subtitle ? `/${mainTitle}` : "/"}
                            mainTitle={mainTitle}
                            mainTitleDescription={mainTitleDescription}
                            buttons={
                                (mainTitleDescription || subtitleDescription) && <this.buttons />
                            }
                        />

                        <div className="row" ref={el => (this.componentRef = el)}>

                            <div className={` col-lg-12 ${this.state.projectMenu && (mainTitleDescription || subtitleDescription) ? "col-xl-9 " : "col-xl-12"}`} >
                                <div className="card custom-card">

                                    <div className="card-body " ref={el => (this.componentRef = el)}>

                                        <section className="table-responsive allView-docs scroll-widget overflow-auto mt-2 px-3 w-100">

                                            {has_subtitle !== true &&
                                                (mainTitleDescription ?
                                                    (<section className="wrapper__content">
                                                        <div className="content__default">
                                                            <Markup content={singleDocInfo.title_description ?? mainTitleDescription} />
                                                        </div>
                                                    </section>)
                                                    :
                                                    <>
                                                        <NoContent onClick={this.onClickAddDescription} />

                                                        {/* (<TextEditor
                                                    subtitle={subtitle}
                                                    subtitleID={subtitleID}
                                                    mainTitle={mainTitle}
                                                    noSubDocs
                                                    addDescription={this.saveMainDocDescription}
                                                />) */}
                                                    </>
                                                )
                                            }

                                            {has_subtitle && countActiveLocationPath > 2 &&
                                                (!subtitleDescription ?
                                                    <>
                                                        <NoContent onClick={this.onClickAddDescription} />

                                                        {/* (<TextEditor
                                                    subtitle={subtitle}
                                                    subtitleID={subtitleID}
                                                    mainTitle={mainTitle}
                                                    hasSubtitle
                                                    addSubtitleDescription={this.addSubTitle}
                                                />) */}
                                                    </>
                                                    :
                                                    (<section className="wrapper__content">
                                                        <div className="content__default">
                                                            <Markup content={subtitleDescription} />
                                                        </div>
                                                    </section>)
                                                )
                                            }

                                            {has_subtitle && countActiveLocationPath === 2 &&
                                                (mainTitleDescription ?
                                                    (<section className="wrapper__content">
                                                        <div className="content__default">
                                                            <Markup content={mainTitleDescription} />
                                                        </div>
                                                    </section>)
                                                    :
                                                    <>
                                                        <NoContent onClick={this.onClickAddDescription} />

                                                        {/* (<TextEditor
                                                    subtitle={subtitle}
                                                    subtitleID={subtitleID}
                                                    mainTitle={mainTitle}
                                                    addDescription={this.saveMainDocDescription}
                                                />) */}
                                                    </>
                                                )
                                            }

                                        </section>

                                    </div>
                                </div>
                            </div>

                            {this.state.projectMenu && (mainTitleDescription || subtitleDescription) && <ProjectMenu
                                doc_id={(has_subtitle && countActiveLocationPath > 2) ? subtitleID : maintitleID}
                                doc_ref={(has_subtitle && countActiveLocationPath > 2) ? subtitleDocRef : mainDocRef}
                                pageHeadingsList={(has_subtitle && countActiveLocationPath > 2) ? "" : pageHeadings}
                                onSaveHeading={this.onSaveMainDocHeading}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DocManager;
