import React from 'react';


class AlertSuccess extends React.Component {
  

  render(){
    
      return (
        <div className="alert alert-success mg-b-10" role="alert">
       {
         /*
          <button aria-label="Close" className="close" data-dismiss="alert" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
         */
       }
       
            <strong>Success! </strong>{this.props.message}
        </div>

      );
    
 
  }
}

export default AlertSuccess;