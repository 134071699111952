import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import SyntaxHighlighter from 'react-syntax-highlighter';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco, darcula, atomOneDark, vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeBlock = ({ codeString, title }) => {

    const [copy, setCopy] = useState(false);

    const onClickCopy =  async() => {
        await  navigator.clipboard.writeText(codeString)
        setCopy(true)
        setTimeout(() => {
            setCopy(false)
        }, 3000)
    }

    return (
        <div className='code-snippet'>
            <div className='code-snippet-header'>
                <span className='code-btn text-white'>{title}</span>

                {!copy ?
                    <span className="code-btn text-white" onClick={onClickCopy}>
                        <i className="far fa-copy mr-2"></i> Copy
                    </span>
                    :
                    <span className="code-btn text-white">
                        <i className="far fa-copy mr-2"></i> Copied
                    </span>
                }
            </div>

            <SyntaxHighlighter
                language="jsx"
                style={vscDarkPlus}
                wrapLines={true}
                customStyle={{
                    padding: "12px 20px",
                    fontFamily: "roboto"
                }}
                // showLineNumbers={true}
                codeTagProps={{
                    style: {
                        fontFamily: "source - code - pro, Menlo, Monaco, Consolas, 'Courier New', monospace"
                    }
                }}
            >
                {codeString}
            </SyntaxHighlighter>
        </div>
    )

}

export default CodeBlock;
