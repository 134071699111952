import dictionary from "../dictionary";
import apiCall from "./apiCall";

const ACCESS_TOKEN = localStorage.getItem('pmisdocs@user');
const apiHost = dictionary.apiHost

export default {


    async getRolePermissions(role_id) {

        let data = {
            "role_id": role_id
        }

        try {
            let response = await fetch(apiHost + 'permission/role/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + ACCESS_TOKEN
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },


    async getUserAccessCodes(user_id) {

        let data = {
            "user_id": user_id
        }

        let response = await apiCall("access/user/codes", data);
        return response;

    },

    async getRoleAccessCodes(role_id) {

        let data = {
            "role_id": role_id
        }

        let response = await apiCall("access/role/codes", data);
        return response;

    },



}