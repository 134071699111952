import React from 'react'
import Cookies from 'universal-cookie';
import AlertError from '../../Components/Common/AlertError';
import dictionary from '../../utils/dictionary';
import ajax from '../../utils/ajax';
import functions from '../../utils/functions';
import AlertSuccess from '../../Components/Common/AlertSuccess';


class PasswordReset extends React.Component {

    state = {
        password: '',
        confirmPassword: '',
        editedPasswordUserId: '',
        userRecord: false,
        error: false,
        info: '',
        loading: false
    }

    componentDidMount() {
        // if (!this.props.location.state) {
        //     this.props.history.push('/users');
        //   } else {
        //     this.setState({
        //       userRecord:this.props.location.state.record
        //     }, ()=>{

        //       this.setState({
        //         editedPasswordUserId:this.state.userRecord.user_id
        //       })
        //     })
        //   }
    }

    // handleValidatePassword = () => {
    //     const { password, confirmPassword } = this.state;
    //     // perform all neccassary validations
    //     if (password !== confirmPassword) {
    //         alert("Passwords don't match");
    //         this.setState({
    //             info: <AlertError message={dictionary._enterMatchingPasswords} />,
    //             loading: ''
    //         })
    //     } else {
    //         // make API call
    //     }
    // }

    onPressReset = async (event) => {
        event.preventDefault();
        const { password, confirmPassword } = this.state;

        if (
            password.length > 0 &&
            confirmPassword.length > 0
        ) {

            if (password !== confirmPassword) {
                this.setState({
                    info: <AlertError message={dictionary._enterMatchingPasswords} />,
                    loading: ''
                })
            } else {
                const server_response = await ajax.resetPassword(password);
                if (server_response.status === "OK") {
                    localStorage.removeItem('pmisdocs@user');
                    window.location.replace('/login');

                } else {

                    this.setState({
                        info: <AlertError message={server_response.details.message} />
                    })

                }
            }
        } else {
            this.setState({
                info: <AlertError message={dictionary._completeFields} />,
                loading: ''
            })
        }


    }

    onChangePassword = (event) => {
        this.setState({ password: event.target.value });
    }

    onConfirmPassword = (event) => {
        this.setState({ confirmPassword: event.target.value });
    }

    //show password when when u click checkbox
    showPassword = () => {
        let input = document.getElementById("password");
        let input2 = document.getElementById("password2");
        if (
            input.type === "password" &&
            input2.type === "password"
        ) {
            input.type = "text";
            input2.type = "text";
        } else {
            input.type = "password";
            input2.type = "password"
        }
    }



    render() {


        return (
            // <div class="page main-signin-wrapper">

            <div className="reset-body d-flex flex-column">
                <div className="m-auto">
                    <div className="container-fluid">
                        <div className="card login-card">
                            <div className="card-body">
                                {/* <h2 className="login-card-title">Logo</h2> */}
                                <div className="text-center mb-4 mt-2">
                                    <img src={process.env.PUBLIC_URL + "../../images/logo-normal.svg"} className="header-brand-img" alt="logo" />
                                    <img src={process.env.PUBLIC_URL + "../../images/logo-normal.svg"} className="header-brand-img theme-logos" alt="logo" />
                                </div>
                                <p className="login-card-description mt-2">Reset your password</p>
                                {this.state.info}
                                <form method="post" onSubmit={this.onPressReset}>
                                    <div className="form-group">
                                        <label htmlFor="password" className="sr-only">New Password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            className="form-control"
                                            placeholder="New Password"
                                            onChange={this.onChangePassword} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password" className="sr-only">Confirm Password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password2"
                                            className="form-control"
                                            placeholder="Confirm New Password"
                                            onChange={this.onConfirmPassword} />
                                    </div>
                                    <div className="form-options-wrapper">
                                        <div className="custom-control custom-checkbox login-card-check-box">
                                            <input type="checkbox" className="custom-control-input" id="customCheck1" onClick={this.showPassword} />
                                            <label className="custom-control-label" htmlFor="customCheck1">Show password</label>
                                        </div>
                                    </div>
                                    {/* <input className="btn btn-block login-btn" type="button" value="Login" /> */}
                                    <button className="btn ripple btn-block login-btn mt-4">Reset Password</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div>
          <div className="container">
            <div className="footer-content-wrapper">
              <p className="mb-sm-0">Copyright 2020  Thrivetecug.co.ug</p>
              <nav>
                <a href="#!">Terms of use.</a>
                <a href="#!">Privacy policy</a>
              </nav>
            </div>
          </div>
        </div> */}
            </div>


            // </div>


        );

    }
}

export default PasswordReset;
