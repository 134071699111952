import React from 'react';
import dictionary from '../../utils/dictionary';
import AlertError from '../Common/AlertError';
import TextInput from '../Common/TextInput';
import ActivityLoader from '../Common/ActivityLoader';

class PopAddTitle extends React.Component {

    state = {
        titleName: '',
        description: '',
        info: '',
        toast: false,
        modal: '',
        loading:false
    }

    onChangeTitleText = (event) => {
        this.setState({
            titleName: event.target.value
        })
    }

    onConfirm = (event) => {
        event.preventDefault();
        const { titleName, description } = this.state;
        if (titleName.length > 0) {
            this.setState({
                loading:true
            }, ()=>{
                this.props.onConfirm(titleName, description);
            })
           
        } else {
            this.setState({
                info: ''
            }, () => this.setState({
                info: <AlertError message={dictionary._requireTitleText} />
            })
            )
        }
    }

    
    render() {

        return (
            <div className="modal" id="modal_add_title">
                <div className="modal-dialog modal-dialog-centered" role="document">

                    <div className="modal-content modal-content-demo shadow">
                        <div className="modal-header">
                            <h6 className="modal-title">Create New Title</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                        </div>

                        <div className="modal-body">
                            {this.state.info}
                            <form method="post" onSubmit={this.onConfirm}>
                                <TextInput
                                    label="New title text"
                                    type="text"
                                    onChange={() => this.onChangeTitleText}
                                    value={this.state.titleName}
                                />
                            </form>
                        </div>

                        <div className="modal-footer">
                            {!this.state.loading && <>
                            <button className="btn ripple btn-primary" type="button" onClick={this.onConfirm}>Confirm Title</button>
                            <button className="btn ripple btn-secondary" type="button" onClick={this.onClose} data-dismiss="modal">Close</button>
                            </>}
                            {this.state.loading && <ActivityLoader/>}
                        </div>
                    </div>

                </div>
            </div>
        )

    }
}

export default PopAddTitle;
