import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from 'react-router-dom';

// import Dashboard from './Pages/Operations/Dashboard';
import functions from './utils/functions';

import './assets/css/App.css';
import './assets/css/codeblock.css';
import './assets/css/monitor.css';
// import ProjectDocument from './Pages/Documentation/WelcomeDocument';
import InstallationDocument from './Pages/Documentation/InstallationDocument';
import WelcomeDocument from './Pages/Documentation/WelcomeDocument';
import DocManager from './Pages/Documentation/DocManager';
import SuperProvider from './Context/SuperProvider';

import { AuthConsumer } from './Context/AuthContext';
import WelcomePage from './Pages/WelcomePage';
import LoadingPage from './Pages/auth/LoadingPage';
import PopSystemSearch from './Components/Common/PopSystemSearch';
import Login from './Pages/auth/Login';
import PasswordReset from './Pages/auth/ResetPassword';
import { RenderSecure } from './utils/script/RenderSecure';
import ProjectDocument from './Pages/Documentation/ProjectDocument';
import APIDocument from './Pages/Documentation/ApiDocument';

class App extends React.Component {

  state = {
    screen: "Login",
    loggedIn: true,
    accessInfo: functions.permissionGuard(),

    notification: {
      title: '',
      body: ''
    },
    showToast: false,
    isTokenFound: false,
    getFcmToken: '',
  }

  componentDidMount() {
    this.checkLogin();
    this.getToken();
  }

  getToken() {
    // fetchToken(this.state.isTokenFound, this.state.getFcmToken);
  }



  resetToast = () => {
    this.setState({
      showToast: false
    })
  }

  checkLogin() {
    if (!localStorage.getItem("pmisdocs@user")) {
      this.setState({
        loggedIn: false
      })
    } else {
      this.setState({
        loggedIn: true
      })
    }
  }

  // dashboards = () => {

  //   return (
  //     <AuthConsumer>
  //       {props => {
  //         const accessInfo = props.permissionList

  //         if (functions.findInPermission(accessInfo, "R-DOCUMENTATION-00")) {
  //           return (
  //             <DocManager />
  //           )
  //         }
  //         else {
  //           return (
  //             <WelcomePage />
  //           )
  //         }

  //       }}

  //     </AuthConsumer>

  //   )
  // }

  render() {

    return (
      <SuperProvider>
        {this.state.showToast}
        <AuthConsumer>
          {props => {
            const permissionList = props.permissionList
            if (!permissionList && this.state.loggedIn) {
              return (
                <LoadingPage />
              )
            } else {
              return (
                <div id="app_container" onClick={functions.closeHeaderDropdowns}>
                  <Router forceRefresh={false}>
                    {!this.state.loggedIn && <Redirect to="/login" />}
                    <PopSystemSearch />
                    <Switch>
                      {!this.state.loggedIn && <Route exact path="/" component={Login} />}

                      {/* {USER_BOUNDARY !== "VENDOR" && */}
                      {/* <> */}

                      {/* {permissionList && <Route exact path="/" component={this.dashboards} />} */}
                      <Route exact path="/login" component={Login} />

                      <Route exact path="/password-reset" component={PasswordReset} />
                      <Route exact path="/unauthorized" component={WelcomePage} />
                      {/* <Route exact path="/" component={this.dashboards} /> */}

                      {/* # DOCUMENTATION WORK */}
                      {/* <RenderSecure code="R-DOCUMENTATION-00"> */}
                      <Route exact path="/" component={WelcomeDocument} />
                      <Route exact path="/installation-guide" component={InstallationDocument} />
                      <Route exact path="/:title" component={DocManager} />
                      <Route exact path="/:title/:subtitle" component={DocManager} />
                      {/* <Route exact path="/get-started/welcome" component={WelcomeDocument} /> */}
                      

                      <Route exact path="/documentation/api-docs" component={APIDocument} />
                      <Route exact path="/documentation/project-docs" component={ProjectDocument} />
                      {/* </RenderSecure> */}
                      {/* </>
                      } */}
                    </Switch>
                  </Router>
                </div>
              )
            }
          }}
        </AuthConsumer>
      </SuperProvider>
    )

  }

}

export default App;
