/* eslint-disable import/no-anonymous-default-export */
import dictionary from "./dictionary";
import apiCall from "./remote/apiCall";
import mainApiCall from "./remote/apiMainCall";

const ACCESS_TOKEN = localStorage.getItem('pmisdocs@user');

const apiHost = dictionary.apiHost

export default {

    async loginUser(username, password) {

        let data = {
            "username": username,
            "password": password,
            "app_type": ""
        }
        const response = mainApiCall("user/login", data);
        return response;
    },

    async userInfo(user_id) {

        let data = {
            "user_id": user_id,
        }
        const response = mainApiCall("user/profile", data);
        return response;
    },

    
    async resetPassword(password) {

        let data = {
            "password": password
        }

        const response = mainApiCall("user/auth/password.reset", data);
        return response;

    },


}


