import React from 'react';
// import nocontent from '../../assets/no-content.svg';
import nocontent from '../../assets/images/no_data.svg';

class NoContent extends React.Component {


  render() {

    return (
      <div className="col-12">
        {this.props.into &&
          <p className='d-flex align-items-center mb-3 bg-info-transparent p-3'>
            <span><i className="mr-3 fs-30 text-info fas fa-info-circle"></i></span>

            <span>
              To create sub-pages for this specific topic in your document, click the <span><i className="fs-20 text-primary fas fa-plus"></i></span> icon just next to the title you have created. If you are not interested in adding any subtitles, you can proceed and add details to the topic in your document in the text editor below.
            </span>

          </p>
        }

        <div className="py-4 my-2 d-flex align-items-center justify-content-center flex-column text-center">
          {/* <img
            src={process.env.PUBLIC_URL + "../../images/logo-normal.svg"}
            alt="logo"
            width="100px"
            className="img-fluid mx-auto d-block"
          /> */}
          <img
            // src={this.props.image || process.env.PUBLIC_URL + "../../assets/images/no_data.svg"}
            src={nocontent}
            alt="logo"
            width="200px"
            className="img-fluid mx-auto d-block my-4"
          />
          {/* <h3 className="notfound-color">{this.props.title || "Oops! Multiplex says:"}</h3> */}
          <p className={`text-muted ${(this.props.width) ? (this.props.width) : 'w-25'}`}>{this.props.subTitle || "There seems not be any available data. To add some content, Click the button below"}</p>
          {this.props.onClick && <button className="btn ripple btn-primary mt-3" type="button" onClick={this.props.onClick}>Add</button>}
        </div>
      </div>

    );


  }
}

export default NoContent;
